import TypeConst from "./consts";

export default function todosReducer(state = {
    error: null,
    isLoad: false,
    title: '',
    isAuth: false,
    accessToken: null,
    refreshToken: null,
    menu: false,
    expAccessToken: null,
    expRefreshToken: null,
    filter: false,
    lang: null,
    userPermissions: [],
    menuGroupsState: [],
}, action) {
    switch (action.type) {
        case TypeConst.SET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case TypeConst.SET_STATUS_LOAD:
            return {
                ...state,
                isLoad: action.isLoad
            };
        case TypeConst.SET_TITLE:
            return {
                ...state,
                title: action.title
            };
        case TypeConst.SET_ACCESS_TOKEN:
            return {
                ...state,
                isAuth: (action.accessToken) ? true : false,
                accessToken: action.accessToken
            };
        case TypeConst.SET_REFRESH_TOKEN:
            return {
                ...state,
                refreshToken: action.refreshToken
            };
        case TypeConst.SET_EXP_ACCESS_TOKEN:
            return {
                ...state,
                expAccessToken: action.expAccessToken
            };
        case TypeConst.SET_EXP_REFRESH_TOKEN:
            return {
                ...state,
                expRefreshToken: action.expRefreshToken
            };
        case TypeConst.SET_MENU:
            return {
                ...state,
                menu: action.menu
            };
        case TypeConst.SET_FILTER:
            return {
                ...state,
                filter: action.filter
            };
        case TypeConst.SET_LANG:
            return {
                ...state,
                lang: action.lang
            };
        case TypeConst.SET_USER_PERMISSIONS:
            return {
                ...state,
                userPermissions: action.userPermissions
            };
        case TypeConst.UPDATE_MENU_GROUP_STATE:
            const index = state.menuGroupsState.findIndex(item => item.slug === action.slug);
            if (index !== -1) {
                // Если элемент с таким slug уже существует, меняем его состояние
                return {
                    ...state,
                    menuGroupsState: state.menuGroupsState.map(item =>
                        item.slug === action.slug ? { ...item, value: !item.value } : item
                    )
                };
            } else {
                // Если элемент с таким slug не существует, добавляем новый объект
                return {
                    ...state,
                    menuGroupsState: [...state.menuGroupsState, { slug: action.slug, value: true }]
                };
            }

        default:
            return state;
    }
}
