//Реакт
import React from "react";
//Компоненты
import Label from "../label";
import MessageError from "../../message/__errors";
//Material UI
import Switch from '@mui/material/Switch';
//Стили
import '../styles.css';
import './styles.css'

export default function InputCheckbox({value = false, readOnly=false, children, placeholder, errors, className = '', ...other}){
    if(placeholder === undefined) placeholder = children;
    return <>
        <Label>{children}</Label>
        <Switch
            {...other}
            checked={value ? true : false}
            className={`input-checkbox ${className} ${readOnly ? 'input-checkbox_read-only' : ''}`}
            placeholder={placeholder}
        />
        {errors ? <MessageError errors={errors} sx={{width: "100%"}}/> : null}
    </>
}