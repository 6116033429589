import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";
import Formatter from "../../../../models/Formatter";

export class OrderAmoFormatter extends Formatter {
  static CustomItem(data) {
    return {
      date_end: this.Date(data.date_end),
      date_pay: this.Date(data.date_pay),
      date_use: this.Date(data.date_use),
    };
  }
}
export default class ApiOrderAmo extends ApiModelWithAuth {
  pathDef = 'authorized/admin/order/amo-orders';

  formatterItem = OrderAmoFormatter.Item;
}