//Реакт
import React from 'react';
//Стили
import './styles.css';

export default function Products({ selectedProduct, products = [], onChange, countPeople, className = '', checkMinutes = true }) {
    const handleClick = (value) => {
        if (checkMinutesByCount(value)) {
            onChange(value);
        } else if (selectedProduct === value) {
            onChange(null);
        }
    };

    const checkMinutesByCount = (minutes) => {
        let k = minutes / countPeople;
        return checkMinutes ? (k >= 2 && k <= 15) : true;
    };

    return (
        <div className={`products__content ${className}`}>
            {products.length > 0 ? products.map((product, index) => (
                <div
                    key={index}
                    className={`${selectedProduct === product ? 'products__content-item--selected' : 'products__content-item'} ${!checkMinutesByCount(product) && products.length > 1 ? 'products__content-item--disabled' : ''}`}
                    onClick={() => handleClick(product)}
                >
                    {product}
                </div>
            )) : <div className="products__content--empty">Выбор продукта не предоставляется</div>
            }
        </div>
    );
}