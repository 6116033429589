//Реакт
import React, { useState, useLayoutEffect, useContext, useEffect } from "react";
//Библиотеки и Api
import ApiParameter from "../../../../../app/api/authorized/admin/statistic/ApiParametr";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Компоненты
import InputWrapper from "../../../../components/input/__wrapper/input__wrapper";
import InputNumber from "../../../../components/inputs/number/number";
import InputTextArea from "../../../../components/inputs/textarea/textarea";
import Button from "../../../../components/button/button";
//Стили
import './styles.css';
import '../../../styles.css'

export default function ParametrIndex({ ...other }) {
    return (
        <Page {...other}>
            <ParametrIndexContent {...other} />
        </Page>
    );
}

function ParametrIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);
    const [_amoState, set_amoState] = useState(false);
    const [_allTasks, set_allTasks] = useState(0);
    const [_runTasks, set_runTasks] = useState(0);
    const [_amoCode, set_amoCode] = useState('');

    useLayoutEffect(() => {
        contextPage.setTitle('Параметры');
        contextContent.setTitle('Параметры');
    }, [contextContent, contextPage]);

    const connectAmo = async () => {
        try {
            const apiStore = ApiParameter.Store({code: _amoCode});
            const responseData = await apiStore.getResponse();
            if(responseData.success && responseData.success ===true){
                set_amoState(true)
            }
        } catch (error) {}
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiIndex = ApiParameter.Index({});
                const responseData = await apiIndex.getResponse();
                set_amoState(responseData.amo.amo_auth ?? null);
                set_allTasks(responseData.queues.countAll ?? 0);
                set_runTasks(responseData.queues.countRun ?? 0);

            } catch (error) {}
        };

        fetchData();
    }, []);

    return (
        <>
            <h3>Очереди</h3>
            <InputWrapper>
                <InputNumber className="params__margin-top" value={_allTasks} readOnly={true}>Всего задач</InputNumber>
            </InputWrapper>
            <InputWrapper>
                <InputNumber className="params__margin-top" value={_runTasks} readOnly={true}>Задач на выполнении</InputNumber>
            </InputWrapper>
            <h3><a href="https://flystationspbth7.amocrm.ru/amo-market/#category-installed" target="_blank">AmoCrm</a></h3> 
            {!_amoState ?
                <div>
                    <InputWrapper>
                        <InputTextArea className="params__margin-top" value={_amoCode} onChange={e => set_amoCode(e.target.value)}>Код авторизации (действителен 20 минут)</InputTextArea>
                    </InputWrapper>
                    
                    <InputWrapper>
                        <Button className="params__margin-top"  onClick={connectAmo}>Подключить</Button>
                    </InputWrapper>    
                </div>
            : 
            <div>
                Интеграция AmoCrm успешно работает!
            </div>
            }        
        </>
    );
}