//Реакт
import React, { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
//Модули Content
import Page from "../../../modules/page";
//Системные представления
import { SystemAbort } from "./Abort";
//Хранилище
import { setTitle as setTitleRedux } from "../../../../app/reducers/app/actions";

export default function SystemAbort403WithPage() {
    const dispatch = useDispatch();
    useLayoutEffect(()=>{
        dispatch(setTitleRedux("Ошибка доступа"));
    });
    return <Page type="error">
        <SystemAbort403 />
    </Page>
};
export function SystemAbort403() {
    return <SystemAbort code={403} text="Ошибка доступа">
        Ошибка доступа
    </SystemAbort>
};