//Реакт
import React, { useState } from "react";
//Mui Icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
//Стили
import './styles.css';

export default function ToolTip({ text, ...other }) {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    return (
        <>
            <div className="tool-tip__icon">
                <InfoOutlinedIcon onMouseEnter={() => setIsTooltipVisible(true)} onMouseLeave={() => setIsTooltipVisible(false)} />
            </div>
            {isTooltipVisible && (
                <div className="tool-tip__content">
                    {text}
                </div>
            )}
        </>
    );
}