//Реакт
import React, { useContext } from "react";
//Контент
import { ContextList } from "../listContext";
//Material UI
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
//Стили
import "./styles.css";

export default function ListZoom({step = 10, min = 10, max = 300, }){
    const {zoomLevel, setZoomLevel} = useContext(ContextList);
    const handleZoomIn = () => {
        if(zoomLevel < max)
            setZoomLevel((prevZoomLevel) => prevZoomLevel + step);
    };
    const handleZoomOut = () => {
        if(zoomLevel > min)
            setZoomLevel((prevZoomLevel) => prevZoomLevel - step);
    };
    return <div className="zoom__box">
    <div>
        Размер: {zoomLevel} %
    </div>
    <div className="universal-button" onClick={handleZoomIn}>
        <ZoomInOutlinedIcon/>
    </div>
    <div className="universal-button"  onClick={handleZoomOut}>
        <ZoomOutOutlinedIcon/>
    </div>
</div>
}