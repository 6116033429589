import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";
import Formatter from "../../../../models/Formatter";

export class CategoryFormatter extends Formatter{}
export default class ApiCategory extends ApiModelWithAuth{
    pathDef = 'authorized/admin/content/categories';

    formatterItem = CategoryFormatter.Item;
}

