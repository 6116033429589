import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";
import Formatter from "../../../../models/Formatter";

export class BalanceFormatter extends Formatter{}
export default class ApiBalance extends ApiModelWithAuth{
    pathDef = 'authorized/admin/booking/balance';

    formatterItem = BalanceFormatter.Item;
}

