//Реакт
import React from "react";
//Компоненты
import Tile from "./components/tile";
//Стили
import './styles.css';

export default function TilePanel({  tiles = [], className = '', ...other }) {
  return (
    <>
        <div className="tile-panel">
            {tiles.map((tile, index) => <Tile key={index} className={className} tile={tile}/>)}
        </div>
    </>
  );
}
