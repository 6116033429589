//Реакт
import React, { useState, useContext, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiCategoryCalendar from "../../../../../../app/api/authorized/admin/event/ApiCategoryCalendar";
import DateLib from "../../../../../../inc/DateLib";
//Модули Content
import Page from "../../../../../modules/page";
import { ContextPage } from "../../../../../modules/page/pageContext";
import { ContextContent } from "../../../../../modules/content/contentContext";
//Компоненты
import Select from "../../../../../components/inputs/select";
import InputDate from "../../../../../components/inputs/date/date";
import InputText from "../../../../../components/inputs/text";
import InputWrapper from "../../../../../components/input/__wrapper";
import InputDatetime from "../../../../../components/inputs/datetime/datetime";
import InputCheckbox from "../../../../../components/inputs/checkbox/checkbox";
//Стили
import './styles.css';

export default function CategoryCalendarShow({ ...other}){
  return <Page ApiModel={ApiCategoryCalendar} { ...other}>
    <CategoryCalendarShowContent { ...other}/>
  </Page>
}

function CategoryCalendarShowContent({ ...other}){
  const contextPage = useContext(ContextPage);
  const contextContent = useContext(ContextContent);
  const [_id, set_id] = useState(null);
  const [_categoryId, set_categoryId] = useState(null);
  const [_isOff, set_isOff] = useState(false);
  const [_dateStart, set_dateStart] = useState(null);
  const [_dateEnd, set_dateEnd] = useState(null);
  const [_created, set_created] = useState(null);
  const [_updated, set_updated] = useState(null);

  const categories = useSelector(state => state.adminOption.productCategories);

  const handleCategoryChange = (event) => {
      set_categoryId(event.target.value);
  };

  const handleStateChange = (event) => {
    set_isOff(!_isOff);
  };

  contextContent._dataToServer.current = {
    category_id: _categoryId,
    date_start: _dateStart,
    date_end: _dateEnd,
    is_off: _isOff,
  };

  useLayoutEffect(()=>{
    contextContent._handleSetData.current = (data) =>{
      set_id(data.id);
      set_categoryId(data.category_id);
      set_isOff(data.is_off);
      set_dateStart(data.date_start);
      set_dateEnd(data.date_end);
      set_created(data.created_at);
      set_updated(data.updated_at);
      contextPage.setTitle(`Календарь категории:  ${data.category_name}`);
      contextContent.setTitle(`Календарь категории:  ${data.category_name}`);
      contextPage.setName(data.category_name);
    };
    contextContent.doLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return <>
    {!contextContent.isCreate ?
      <InputWrapper>
        <InputText className="pages__show-input" value={_id} readOnly={true}>id</InputText>
      </InputWrapper> 
    : null }
    <InputWrapper>
        <Select className="pages__show-input" options={categories} value={_categoryId} readOnly={!contextContent.isCreateOrEdit} onChange={handleCategoryChange}>Категория</Select>
    </InputWrapper>
    <InputWrapper>
        <InputDate className="pages__show-input" value={DateLib.formatterDate(_dateStart)} readOnly={!contextContent.isCreateOrEdit} onChange={(e) => { set_dateStart(e.target.value) }}>Дата начала</InputDate>
    </InputWrapper>
    <InputWrapper>
        <InputDate className="pages__show-input" value={DateLib.formatterDate(_dateEnd)} readOnly={!contextContent.isCreateOrEdit} onChange={(e) => { set_dateEnd(e.target.value) }}>Дата окончания</InputDate>
    </InputWrapper>
    <InputWrapper>
        <InputCheckbox className="pages__show-input" value={_isOff} readOnly={!contextContent.isCreateOrEdit}  onChange={handleStateChange}>Работает/не работает</InputCheckbox>
    </InputWrapper>
    {!contextContent.isCreate ? 
      <InputWrapper>
        <InputDatetime className="pages__show-input" value={_created} readOnly={true}>Создан</InputDatetime>
      </InputWrapper> 
    : null }
    {!contextContent.isCreate ? 
      <InputWrapper>
        <InputDatetime className="pages__show-input" value={_updated} readOnly={true}>Изменен</InputDatetime>
      </InputWrapper> 
    : null }
  </>
}
