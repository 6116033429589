//Реакт
import React, { useState, useContext, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiBanner from "../../../../../app/api/authorized/admin/event/ApiBanner";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Компоненты
import Select from "../../../../components/inputs/select";
import InputText from "../../../../components/inputs/text";
import InputWrapper from "../../../../components/input/__wrapper";
import InputDatetime from "../../../../components/inputs/datetime/datetime";
import InputNumber from "../../../../components/inputs/number/number";
import InputCheckbox from "../../../../components/inputs/checkbox/checkbox";
import InputFile from "../../../../components/inputs/file/file";
//Стили
import './styles.css';

export default function BannerShow({ ...other}){
  return <Page ApiModel={ApiBanner} { ...other}>
    <BannerShowContent { ...other}/>
  </Page>
}

function BannerShowContent({ ...other}){
  const contextPage = useContext(ContextPage);
  const contextContent = useContext(ContextContent);
  const [_id, set_id] = useState(null);
  const [_name, set_name] = useState('');
  const [_file, set_file] = useState(null);
  const [_priority, set_priority] = useState(0);
  const [_isActive, set_isActive] = useState(0);
  const [_urlFile, set_urlFile] = useState('');
  const [_isNewTab, set_isNewTab] = useState(0);
  const [_urlPage, set_urlPage] = useState('');
  const [_typeId, set_typeId] = useState(null);
  const [_dateStart, set_dateStart] = useState(null);
  const [_dateEnd, set_dateEnd] = useState(null);
  const [_isAlwaysActive, set_isAlwaysActive] = useState(0);
  const [_created, set_created] = useState(null);
  const [_updated, set_updated] = useState(null);
  const [_timestamp, set_timestamp] = useState(new Date().getTime());

  const bannerTypes = useSelector(state => state.adminOption.bannerTypes);
  
  const handleTypeChange = (event) => {
      set_typeId(event.target.value);
  };

  const handleIsActiveChange = () => {
    set_isActive(Number(!_isActive));
  }

  const handleIsNewTabChange = () => {
    set_isNewTab(Number(!_isNewTab));
  }

  const handleIsAlwaysActiveChange = () => {
    set_isAlwaysActive(Number(!_isAlwaysActive));
  }

  const handleSetFile = (e)=>{
    let data = e.target.files[0];
    if(data) {
      const alowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'mp4'];
      const fileExtension = data.name.split('.').pop().toLowerCase();
      if(alowedExtensions.includes(fileExtension)){
        set_file(data);
        const reader = new FileReader();
        reader.onload = (e) => {
          set_urlFile(e.target.result);
        };
        reader.readAsDataURL(data);
        
        if(data && contextContent.isCreate){
          set_name(data.name);
        }
      }
    }
  }


  

  contextContent._dataToServer.current = {
    name: _name,
    file: _file,
    priority: _priority,
    is_active: _isActive,
    url_file: _urlFile,
    is_new_tab: _isNewTab,
    date_start: _dateStart,
    date_end: _dateEnd,
    url_page: _urlPage,
    type_id: _typeId,
    always_active: _isAlwaysActive,
  };

  useLayoutEffect(()=>{
    contextContent._handleSetData.current = (data) =>{
      set_id(data.id);
      set_name(data.name);
      set_file(data.file);
      set_priority(data.priority);
      set_isActive(data.is_active);
      set_urlFile(data.url_file);
      set_isNewTab(data.is_new_tab);
      set_urlPage(data.url_page);
      set_typeId(data.type_id);
      set_dateStart(data.date_start);
      set_dateEnd(data.date_end);
      set_isAlwaysActive(data.always_active);
      set_created(data.created_at);
      set_updated(data.updated_at);
      set_timestamp(new Date().getTime());
      contextPage.setTitle(`Баннер:  ${data.name}`);
      contextContent.setTitle(`Баннер:  ${data.name}`);
      contextPage.setName(data.name);
    };
    contextContent.doLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return <>
    {!contextContent.isCreate ?
      <InputWrapper>
        <InputText className="pages__show-input" value={_id} readOnly={true}>id</InputText>
      </InputWrapper> 
    : null }
    <InputWrapper>
      <InputText className="pages__show-input" value={_name} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_name(e.target.value)}>Имя</InputText>
    </InputWrapper>
    <InputWrapper>
        <InputFile className="pages__show-input"  
            timestamp={_timestamp}
            file={_file} value={_name}  
            url={_urlFile} 
            onChange={handleSetFile}
            readOnly={!contextContent.isCreateOrEdit} 
        >Файл</InputFile>
    </InputWrapper>
    <InputWrapper>
      <InputNumber className="pages__show-input" value={_priority} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_priority(e.target.value)}>Приоритет</InputNumber>
    </InputWrapper>
    <InputWrapper>
        <InputCheckbox className="pages__show-input" value={_isActive} readOnly={!contextContent.isCreateOrEdit} onChange={handleIsActiveChange}>Статус</InputCheckbox>
    </InputWrapper>
    <InputWrapper>
        <InputCheckbox className="pages__show-input" value={_isNewTab} readOnly={!contextContent.isCreateOrEdit} onChange={handleIsNewTabChange}>Вкладка</InputCheckbox>
    </InputWrapper>
    <InputWrapper>
        <InputCheckbox className="pages__show-input" value={_isAlwaysActive} readOnly={!contextContent.isCreateOrEdit} onChange={handleIsAlwaysActiveChange}>Всегда активен</InputCheckbox>
    </InputWrapper>
    <InputWrapper>
        <InputText className="pages__show-input" value={_urlPage} readOnly={!contextContent.isCreateOrEdit} onChange={(e) => { set_urlPage(e.target.value) }}>Страница</InputText>
    </InputWrapper>
    <InputWrapper>
        <Select className="pages__show-input" options={bannerTypes} value={_typeId} readOnly={!contextContent.isCreateOrEdit} onChange={handleTypeChange}>Тип</Select>
    </InputWrapper>
    {!contextContent.isCreate ? 
        <InputWrapper>
            <InputText className="pages__show-input" value={_urlFile} readOnly={true}>Путь</InputText>
        </InputWrapper>
    : null }
    <InputWrapper>
        <InputDatetime className="pages__show-input" value={_dateStart} readOnly={!contextContent.isCreateOrEdit} onChange={(e) => { set_dateStart(e.target.value) }}>Дата начала</InputDatetime>
    </InputWrapper> 
    <InputWrapper>
        <InputDatetime className="pages__show-input" value={_dateEnd} readOnly={!contextContent.isCreateOrEdit} onChange={(e) => { set_dateEnd(e.target.value) }}>Дата окончания</InputDatetime>
    </InputWrapper> 
    {!contextContent.isCreate ? 
      <InputWrapper>
        <InputDatetime className="pages__show-input" value={_created} readOnly={true}>Создан</InputDatetime>
      </InputWrapper> 
    : null }
    {!contextContent.isCreate ? 
      <InputWrapper>
        <InputDatetime className="pages__show-input" value={_updated} readOnly={true}>Изменен</InputDatetime>
      </InputWrapper> 
    : null }
  </>
}
