//Реакт
import React, { useContext } from "react";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { setError } from "../../../app/reducers/app/actions"
//Компоненты
import { ContextApp } from "./appContext";
import { ContextMessages } from "./messages/messagesContext";

export function ProviderApp({ children, ...other }) {
    const contextMessages = useContext(ContextMessages);
    const dispatch = useDispatch(); 
    const customEqual = (oldValue, newValue) => {
        if (oldValue.length === newValue.length) return true;
        return false;
    }
    const permissions = useSelector(state => state.app.userPermissions, customEqual);
    const toggleError = useSelector(state => state.app.error);
    const checkPermission = (permission) => {
        if (permissions.includes(permission)) return true;
        return false;
    }
    const pushErrorToStorage = (error) => {
        dispatch(setError(error));
    };
    return <ContextApp.Provider value={{
        messages: contextMessages,
        permissions, checkPermission,
        toggleError, pushErrorToStorage,


    }}>{children}</ContextApp.Provider>
}
