//Реакт
import React from "react";
//Компоненты
import MessageErrors from "../../../../components/message/__errors/message__errors";
//Стили
import './styles.css';
import '../../styles.css'

export default function Input({value = '', type="text", children, placeholder, onChange, className = '', errors, ...other}){
    if(placeholder === undefined) placeholder = children;
    return <>
        <input
            {...other}
            type={type}
            value={value ? value : ''}
            className={`booking-input ${className}`}
            placeholder={placeholder}
            onChange={onChange}
        />
        {errors ? <MessageErrors errors={errors} sx={{width: "100%"}}/> : null}
    </>
}