//Реакт
import React from "react";
//Стили
import "./styles.css";

export default function Filters({filters = [] }){
    return <div className="filters">
        <div className="card">
            <div className="card-header">
                Фильтры
            </div>
            <div className="card-body">
                {filters.map((filter, index) => (
                    <div key={index} className="filter-item">{filter}</div>
                ))}
            </div>
        </div>
    </div>
}