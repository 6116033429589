//Реакт
import React from "react";
//Redux
import { useSelector } from "react-redux";
//Компоненты
import Breadcrumbs from "../../../components/breadcrumbs";
//Стили
import "./styles.css";

export default function PageContainer({children, breadcrumb, ...other}){
  const title = useSelector(state => state.app.title);
  return <div 
    className="page__container"
    {...other}
  >
    <div className="page__container-header">
      <h1>{title}</h1>
      {breadcrumb ? <Breadcrumbs breadcrumb={breadcrumb}/> : null }
    </div>
    <div className="page__container-body">
      {children}
    </div>
  </div>
}