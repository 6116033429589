//Реакт
import React, { useState, useContext, useLayoutEffect, useCallback} from "react";
import ReactDOM from 'react-dom/client';
//Библиотеки и Api
import ApiPromotion from "../../../../../app/api/authorized/admin/content/ApiPromotion";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import InputWrapper from "../../../../components/input/__wrapper";
import InputDatetime from "../../../../components/inputs/datetime/datetime";
import InputFile from "../../../../components/inputs/file/file";
import InputCheckbox from "../../../../components/inputs/checkbox/checkbox";
import ContentEditor from "../../../../components/quill";
//Стили
import './styles.css';
//MUI
import DeleteIcon from '@mui/icons-material/Delete';

export default function PromotionShow({ ...other}){
  return <Page ApiModel={ApiPromotion} { ...other}>
    <PromotionShowContent { ...other}/>
  </Page>
}

function PromotionShowContent({ ...other}){
  const contextPage = useContext(ContextPage);
  const contextContent = useContext(ContextContent);
  const [_id, set_id] = useState(null);
  const [_name, set_name] = useState('');
  const [_file_client, set_file_client] = useState(undefined);
  const [_file_server, set_file_server] = useState(null);
  const [_annotation, set_annotation] = useState('');
  const [_priority, set_priority] = useState(null);
  const [_url, set_url] = useState('');
  const [_slug, set_slug] = useState('');
  const [_date_start, set_date_start] = useState('');
  const [_date_end, set_date_end] = useState('');
  const [_created, set_created] = useState(null);
  const [_updated, set_updated] = useState(null);
  const [_status, set_status] = useState(1);
  const [_content, set_content] = useState(null);
  const [_defaultContent, set_default_content] = useState(null);
  const [_defaultAnnotation, set_default_annotation] = useState(null);
  const [_timestamp, set_timestamp] = useState(new Date().getTime());

  let customDataToServer = {};
  if(_file_client !== undefined) customDataToServer.file = _file_client;

  contextContent._dataToServer.current = {
    name: _name,
    annotation: _annotation,
    priority: _priority,
    url_page: _url,
    slug: _slug,
    date_start: _date_start,
    date_end: _date_end,
    is_active: _status,
    content: _content,
    ...customDataToServer
  };

  useLayoutEffect(()=>{
    contextContent._handleSetData.current = (data) =>{
      set_id(data.id);
      set_name(data.name);
      set_file_server(data.file);
      set_url(data.url_page);
      set_slug(data.slug);
      set_priority(data.priority);
      set_date_start(data.date_start);
      set_date_end(data.date_end);
      set_created(data.created_at);
      set_updated(data.updated_at);
      set_status(data.is_active);
      set_default_content(data.content);
      set_default_annotation(data.annotation)
      set_timestamp(new Date().getTime());
      contextPage.setTitle(`Акция:  ${data.name}`);
      contextContent.setTitle(`Акция:  ${data.name}`);
      contextPage.setName(data.name);
    };
    contextContent.doLoad();
  },[]);
 
  const handleSetFile = (e)=>{
    let data = e.target.files[0];
    if(data) {
      const alowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'mp4'];
      const fileExtension = data.name.split('.').pop().toLowerCase();
      if(alowedExtensions.includes(fileExtension)){
        set_file_client(data);
        const reader = new FileReader();
        reader.onload = (e) => {
          set_file_server({
            url: e.target.result
          })
        };
        reader.readAsDataURL(data);
      }
    }
  }
  const handleFileClear = () =>{
    set_file_client(null);
    set_file_server(null);
  }
  const handleIsStatusChange = () => {
    set_status(Number(!_status));
  }

  return <>
    {!contextContent.isCreate ?
    <InputWrapper>
      <InputText className="pages__show-input" value={_id} readOnly={true}>id</InputText>
    </InputWrapper> 
    : null }
    <InputWrapper>
      <InputText className="pages__show-input" value={_name} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_name(e.target.value)}>Акция</InputText>
    </InputWrapper>
    <InputWrapper>
        <InputFile className="pages__show-input"
            timestamp={_timestamp}
            file={_file_client} value={_file_server ? _file_server.name : null}  
            url = {_file_server ? _file_server.url : null}
            onChange={handleSetFile}
            placeholder={_file_server ? _file_server.name : null}
            readOnly={!contextContent.isCreateOrEdit} 
        >Файл</InputFile>
       {!contextContent.isShow && _file_server ?
          <div onClick={handleFileClear}><DeleteIcon /></div>
        : null }
    </InputWrapper>
    <InputWrapper>
      <ContentEditor className="pages__show-input" value={_annotation} maxLength={500} defaultContent={_defaultAnnotation} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_annotation(e.target.value)}>Краткое описание</ContentEditor>
    </InputWrapper>
    <InputWrapper>
      <InputNumber className="pages__show-input" value={_priority} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_priority(e.target.value)}>Приоритет</InputNumber>
    </InputWrapper>
    <InputWrapper>
      <InputText className="pages__show-input" value={_url} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_url(e.target.value)}>Адрес страницы</InputText>
    </InputWrapper>
    <InputWrapper>
      <InputText className="pages__show-input" value={_slug} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_slug(e.target.value)}>Слаг</InputText>
    </InputWrapper>
    <InputWrapper>
      <InputDatetime className="pages__show-input" value={_date_start} readOnly={!contextContent.isCreateOrEdit}  onChange={e => set_date_start(e.target.value)}>Дата активации</InputDatetime>
    </InputWrapper> 
    <InputWrapper>
      <InputDatetime className="pages__show-input" value={_date_end} readOnly={!contextContent.isCreateOrEdit}  onChange={e => set_date_end(e.target.value)}>Дата окончания</InputDatetime>
    </InputWrapper>
    <InputWrapper>
      <InputCheckbox className="pages__show-input" value={_status} readOnly={!contextContent.isCreateOrEdit}  onClick={handleIsStatusChange}>Актив.</InputCheckbox>
    </InputWrapper>
    <InputWrapper>
      <ContentEditor className="pages__show-input" value={_content} maxLength={1500} defaultContent={_defaultContent} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_content(e.target.value)}>Контент</ContentEditor>
    </InputWrapper>
    {!contextContent.isCreate ? 
      <InputWrapper>
        <InputDatetime className="pages__show-input" value={_created} readOnly={true}>Создан</InputDatetime>
      </InputWrapper> 
    : null }
    {!contextContent.isCreate ? 
      <InputWrapper>
        <InputDatetime className="pages__show-input" value={_updated} readOnly={true}>Изменен</InputDatetime>
      </InputWrapper> 
    : null }
  </>
}
