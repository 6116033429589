//Реакт
import React from "react";
//MUI
import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
//Компоненты
import Button from "../button";
//Стили
import "./styles.css";

export default function DownloadFile({children = null, className = '', data = {}, filename = 'fids-file', ...other}){
   const makeFile = () => {
      let _data = '';
      if(typeof data === 'object') _data = JSON.stringify(data);
      let dataBlob = new Blob([_data], {type: 'application/json"'});
      let csvURL = window.URL.createObjectURL(dataBlob);
      let tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', `${filename}.json`);
      tempLink.click();
   }
   return children ? <Button
      {...other}
      onClick={makeFile}
      className={className}
   >{children}</Button>
   : <IconButton 
      size="large" 
      color="primary"
      onClick={makeFile}
   >
         <DownloadIcon fontSize="inherit"/>
   </IconButton>
}