import config from '../config.json';

const CONFIG = {
    serverUrl: config.serverUrl,
    isDebug:  (config.isDebug) ? config.isDebug : false,
    auth: {
        login: ('auth' in config && 'login' in config.auth) ? config.auth.login : '',
        password: ('auth' in config && 'password' in config.auth) ? config.auth.password : '',
    }
}
export default CONFIG;
