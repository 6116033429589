//Реакт
import React from "react";
//Компоненты
import Messages from "./messages";

import { ProviderApp } from "./appProvider";
//Стили
import "./styles.css";

export default function App({...other}){
  return <div className="app">
    <Messages>
      <AppWithProvider {...other}/>
    </Messages>
  </div>
}
function AppWithProvider({children, ...other}){
  return <ProviderApp
    {...other}
  >
    {children}
  </ProviderApp>
}
