import CONFIG from "../../inc/Config";
import store from "../reducers/store";

export default class ApiInit {
    errors = null;
    static contextApp = null;

    constructor({ baseUrl = "/api", version = "/v1" }) {
        this._baseUrl = CONFIG.serverUrl + baseUrl + version;
    }
    get headers() {
        return {
            "Content-Type": "application/json",
            "Accept": "application/json",
        };
    }
    
    getContextApp() {
        return ApiInit.contextApp;
    }
    getAccessToken() {
        return store.getState().app.accessToken;
    }
    
    getRefreshToken(){
        return store.getState().app.refreshToken;
    }
    
    setIsLoad(status = true) {
        //store.dispatch(setIsLoad(status));
    }
    addMessages(mess, type = 'error'){
        let contextApp = this.getContextApp();
        if(contextApp) contextApp.messages.addMessages(mess, type);
        else throw new Error("ContextApp не найден");
    }
    pushErrorToStorage(error){
        let contextApp = this.getContextApp();
        if(contextApp) contextApp.pushErrorToStorage(error);
        else throw new Error("ContextApp не найден");
    }
    async __query(path, method, data, type = null) {
        let url = `${this._baseUrl}/${path}`;
        let sendData = await this.getSendData({ type, data });
        let options = {
            mode: 'cors',
            method: method,
            headers: this.headers,
        };
        if (sendData) {
            if (type === "searchParams") url += `?${sendData}`;
            else options.body = sendData;
        };
        try {
            let response = await fetch(url, options);
            let json = null;
            try {
                if (response) json = await response.json();
            } catch (error) {}
            return { 
                result: this.checkCode(response.status), 
                response: json, 
                code: response.status 
            };
        } catch (error) {
            console.error(`APP: AJAX general error, url: ${url}`, error);
            return { 
                result: false, 
                response: {message: "Ошибка при запросе на сервер"}, 
                errors: {server: ["Ошибка при запросе на сервер"]},
                code: -1
            };
        }
    }
    checkCode(code){
        if(code >= 200 && code <=204) return true;
        return false;
    }
    async _query(path, method, data, type = null) {
        this.setIsLoad(true);
        let result = await this.__query(path, method, data, type);
        this.setIsLoad(false);
        return result;
    }
    async __queryTemp(data) {
        await new Promise(r => setTimeout(r, 2000));
        return {
            result: true,
            data: { data: [] },
        };
    }

    async getSendData({ type, data }) {
        let sendData = null;
        if (type === "formData") {
            sendData = new FormData();
            Object.keys(data).forEach((key) => {
                let value = data[key]; 
                sendData.append(key, value !== null ? value: '');
            });
        } else if (type === "searchParams") {
            sendData = new URLSearchParams();
            Object.keys(data).forEach((key) => {
                sendData.append(key, data[key]);
            });
        }
        else sendData = JSON.stringify(data);
        return sendData;
    }
    pasreParamsForGet(params) {
        if (!params) return '';
        let _params = '';
        Object.keys(params).forEach((key) => {
            let value = params[key];
            if (value !== null) {
                if (Array.isArray(value)) {
                    value.forEach((item) => {
                        if (_params.length) _params += `&${key}[]=${item}`;
                        else _params += `${key}[]=${item}`;
                    });
                } else if ((value !== undefined) && (value.length || typeof value === 'number')) {
                    if (_params.length) _params += `&${key}=${value}`;
                    else _params += `${key}=${value}`;
                }
            }
        });
        return _params;
    }
}
