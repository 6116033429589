import StorageLocal from "../../../../inc/StorageLocal";
import store from "../../../reducers/store";
import ApiModel from "../../../models/ApiModel";
import InitApp from "../../../../inc/InitApp";

export function setInitData({accessToken = null, refreshToken = null, permissions = []}){
    if(accessToken && refreshToken){
        let data = InitApp.parseTokens({accessToken, refreshToken});
        InitApp.setData({dispatch: store.dispatch, accessToken, refreshToken, permissions, ...data});
        StorageLocal.set('ACCESS_TOKEN', accessToken);
        StorageLocal.set('REFRESH_TOKEN', refreshToken);
        StorageLocal.set('PERMISSIONS', permissions);
        return true;
    }else{
        InitApp.setData({dispatch: store.dispatch, accessToken, refreshToken, permissions});
        StorageLocal.remove('ACCESS_TOKEN');
        StorageLocal.remove('REFRESH_TOKEN');
        StorageLocal.remove('PERMISSIONS');
        return false;
    }
}

export default class ApiAuth extends ApiModel {
    
    static async Login(params){
        const api = new this({});
        api.login(params);
        let rdata = await api.getResponse();
        if(rdata){
            const data = rdata.response
            return setInitData({
                accessToken: data.access_token, 
                refreshToken: data.refresh_token,
                permissions: data.permissions,
            });
        }
        return false;
    }
    login(params){
        this.__type = "POST";
        this.__path = 'public/auth/login';
        this.__params = params;
    }

    static async Logout(){
        const api = new this({});
        api.logout({access_token: api.getAccessToken()});
        await api.getResponse();
        setInitData({});
        return false;
    }
    logout(params){
        this.__type = "POST";
        this.__path = 'public/auth/logout';
        this.__params = params;
    }
    static async RefreshToken(){
        const api = new this({});
        api.refreshToken({
            access_token: api.getAccessToken(), 
            refresh_token: api.getRefreshToken(),
        });
        let rdata = await api.getResponse();
        if(rdata) {
            let data = rdata.response;
            return setInitData({
                accessToken: data.access_token, 
                refreshToken: data.refresh_token,
                permissions: data.permissions,
            });
        }else if(api.code === 422){
            let result = await api.refreshTokenFromStorageLocal();
            if(result) return result;
        }
        await this.Logout();
        return false;
    }
    refreshToken(params){
        this.__type = "POST";
        this.__path = 'public/auth/refresh-token';
        this.__params = params;
    }

    async refreshTokenFromStorageLocal() {
        for(let i =0; i < 20; i++){
            let expAccessToken = store.getState().app.expAccessToken;
            let authData = await InitApp.authDataFromStorageLocal();
            if(authData && expAccessToken < authData.expAccessToken){
                InitApp.setData({dispatch: store.dispatch, ...authData});
                return true;
            }
            await new Promise(resolve => setTimeout(resolve, 500));
        }
        return false;
    }
}
