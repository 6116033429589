//Реакт
import React from "react";
//Стили
import "./styles.css";

export default function Button({children, className = '', type = 'button', onClick, errors, ...other}){
    return <button
        {...other}
        type={type}
        className={`btn ${className}`}
        onClick={onClick}
    >{children}</button>
}