//Реакт
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateMenuGroupState } from "../../../app/reducers/app/actions";
// Компоненты
import MenuItem from "./__item";
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import "./styles.css"; // Подключение файла стилей

export default function MenuItems({ menuItems, groupName, slug, icon, isOpen = false, closeMenu, className='', ...other }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const [isActiveGroup, setIsActiveGroup] = useState(false);
    const isGroupOpen = useSelector(state => state.app.menuGroupsState.find(item => item.slug === slug)?.value);

    const toggleGroup = () => {
        dispatch(updateMenuGroupState(slug));
    };

    useEffect(() => {
        // Check if any path in menuItems matches the current location.pathname
        const foundActive = menuItems.some(item => location.pathname.startsWith(item.path));
        setIsActiveGroup(foundActive);
    }, [location.pathname, menuItems]);

    if (menuItems.length === 0) {
        return null;
    }

    return (
        <div className="menu__items">
            <div className={`menu__items-visual ${isActiveGroup ? 'menu__items-visual--active' : ''} ${className} ${!isOpen ? 'center' : ''} `} onClick={toggleGroup}>
                <div className="menu__items-icon">{icon}</div>
                <div className={`menu__items-name ${isActiveGroup ? 'menu__items-name--active' : ''}`}>{isOpen ? groupName.split(' ').map((word, wordIndex) => (
                    <div key={wordIndex}>{word}</div>
                )) : null}</div>
                <div className={`menu__items-indicator ${isGroupOpen ? 'rotated' : ''}`}>
                    {isOpen && <KeyboardArrowUpOutlinedIcon />}
                </div>
            </div>
            <div className={`menu__items-content ${isGroupOpen ? 'open' : 'close'}`}>
                {menuItems.map((item, index) => (
                    <MenuItem key={index} isOpen={isOpen} closeMenu={closeMenu} Icon={item.icon} path={item.path} active={location.pathname.startsWith(item.path)}>
                        {item.label.split(' ').map((word, wordIndex) => (
                            <div key={wordIndex}>{word}</div>
                        ))}
                    </MenuItem>
                ))}
            </div>
        </div>
    );
}