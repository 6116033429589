//Реакт
import React, { useState } from 'react';
//Стили
import './styles.css'

export default function Counter({ countPeople, min = 1, max = null, onChange, className = '' }) {

    const decrement = () => {
        if (countPeople > min) {
            const newCount = countPeople - 1;
            onChange(newCount);
        }
    };

    const increment = () => {
        if (max != null ? countPeople < max : true) {
            const newCount = countPeople + 1;
            onChange(newCount);
        }
    };

    return (
        <>
            <div className={`counter__content ${className}`}>
                <div className='counter__content-item--button' onClick={decrement}>-</div>
                <div className='counter__content-item'>{countPeople}</div>
                <div className='counter__content-item--button' onClick={increment}>+</div>
            </div>
        </>
    );
}