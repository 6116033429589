//Реакт
import React from "react";
//Компоненты
import Label from "../label";
//Стили
import '../styles.css';

export default function InputEmail({onChange, id = "input-email", className='', children, placeholder, errors, ...other}){
    if(placeholder === undefined) placeholder = children;
    return <>
        <Label>{children}</Label>
        <input
            {...other}
            onChange={onChange}
            id={id}
            type="email"
            className={`booking-content__input input ${className}`}
            placeholder={placeholder}
        />
    </>
}