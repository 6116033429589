//Реакт
import React from "react";
//Библиотеки
import DateLib from "../../../../inc/DateLib";
//Компоненты
import DownloadFile from "../download-file";


export default function DownloadFileError({error, ...other}){
   let data = null;
   if(error instanceof Error){
      if(error.constructor.name === 'ErrorServer') data = error.export();
      else data = {message: error.message, ...error};
   }
   if(!data) return null;
   let now = DateLib.now('en');
   let filename = `fids-error__${now}`;
   return <DownloadFile
      data={data}
      filename={filename}
      {...other}
   />
}