//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
//Библиотеки и Api
import DateLib from "../../../../../inc/DateLib";
import ApiBalance from "../../../../../app/api/authorized/admin/booking/ApiBalance";
import { route } from "../../../../../inc/Routes";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
// import ButtonLinkShow from "../../components/button-link/__show";
// import ButtonLinkAdd from "../../components/button-link/__add";
//Стили
import './styles.css';

export default function BalanceIndex({ ...other }) {
    return <Page {...other}>
        <BalanceIndexContent {...other} />
    </Page>
}

function BalanceIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_id, set_id] = useState('');
    const [_balance, set_balance] = useState('');
    const [_type, set_type] = useState('');
    const [_fio, set_fio] = useState('');

    useLayoutEffect(() => {
        contextPage.setTitle(`Депозиты`);
        contextContent.setTitle(`Депозиты`);
    }, [contextContent, contextPage]);
    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="user"
            name="user"
        >
            Пользователь
        </ListHeaderItem>,
        <ListHeaderItem
            key="balance"
            name="balance"
        >
            Баланс, мин
        </ListHeaderItem>,
        <ListHeaderItem
            key="type"
            name="type"
        >
            Тип
        </ListHeaderItem>,
        <ListHeaderItem
            key="created_at"
            name="created_at"
        >
            Создан
        </ListHeaderItem>,
        <ListHeaderItem
            key="updated_at"
            name="updated_at"
        >
            Изменен
        </ListHeaderItem>,
    ];

    const filters = [
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>id</InputNumber>,
        <InputText className="input_wf" value={_fio} onChange={(e) => { set_fio(e.target.value) }}>Пользователь</InputText>,
        <InputNumber className="input_wf" value={_balance} onChange={(e) => { set_balance(e.target.value) }}>Баланс</InputNumber>,
        <InputText className="input_wf" value={_type} onChange={(e) => { set_type(e.target.value) }}>Тип</InputText>,
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-balances_column-width" key="id" data-label={header[0].props.children}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-balances_column-width" key="user" data-label={header[1].props.children}><a href={route(`authorized.admin.user.users.show`,{id: row.user_id})}>{row.user_fio}</a></ListBodyItem>,
            <ListBodyItem className="admin-balances_column-width" key="balance" data-label={header[2].props.children}>{row.balance}</ListBodyItem>,
            <ListBodyItem className="admin-balances_column-width" key="type" data-label={header[3].props.children}>{row.type_name}</ListBodyItem>,
            <ListBodyItem className="admin-balances_column-width" key="created_at" data-label={header[4].props.children}>{DateLib.formatterDateTime(row.created_at)}</ListBodyItem>,
            <ListBodyItem className="admin-balances_column-width" key="updated_at" data-label={header[5].props.children}>{DateLib.formatterDateTime(row.updated_at)}</ListBodyItem>,
            // <ListBodyItem key="show">
            //     {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.show.url({ id: row.id })} /> : null}
            // </ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiBalance}
            renderRow={renderRow}
            searchParams={{ id: _id, user_fio: _fio, balance: _balance, type: _type }}
        />
    </>
}
