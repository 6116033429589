//Реакт
import React  from "react";
//Модули Content
import Page from "../../../modules/page";
//Стили
import './styles.css';

export default function SystemAbortWithPage({...other}) {
    return <Page>
        <SystemAbort />
    </Page>
};
export function SystemAbort({code, text, children}) {
    return (
        <div className="status-body">
            <div className="status-message status-code">{code}</div>
            <div className="status-message main-text">
                {text}
            </div>
            <div className="status-message">
                {children}
            </div>
        </div>
    );
};