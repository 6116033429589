//Реакт
import React, {useContext} from "react";
//Контент
import { ContextList } from "../listContext";
//Компоненты
import TableCol from "../../../modules/table/__col";
//Material UI
import StraightIcon from '@mui/icons-material/Straight';
import SouthIcon from '@mui/icons-material/South';
//Стили
import "./styles.css";

const styles = {
    fontSize: "0.9em",
    position: "absolute",
}

export default function ListHeaderItem({children, name, search, isSort = true, ...other}){
    
    const contextList = useContext(ContextList);
    if(!contextList) throw new Error("None ProviderSort");
    let iconSort = null;
    if(contextList.sortKey === name){
        if(contextList.sortIsAsc) iconSort = <div className="relative"><StraightIcon sx={styles}/></div>;
        else iconSort = <div className="relative"><SouthIcon sx={styles}/></div>;
    }
    return <TableCol {...other}>
        <div className="list__header-item-content">
            {isSort ? 
            <div className="list__header-item-label" onClick={() => contextList.setSortKey(name)}>{children}{iconSort}</div>
            :
            <div className="list__header-item-label">{children}</div>
            }
        </div>
    </TableCol>
}