//Реакт
import React, { useState, useRef } from "react";
//Компоненты
import { ContextMessages } from "./messagesContext";
//Библиотеки
import DateLib from "../../../../inc/DateLib";

function validMessage({id, message, type, createdAt, ttl}){
   let error = null;
   let mess = '';
   if(message instanceof Error){
      error = message;
      mess = message.message;
   }else{
      mess = message;
   }
   return {id, message: mess, type, createdAt, ttl, error};

}
export function ProviderMessages({children, ...other}){
   const messages= useRef({});
   const [, setLastUpdate] = useState(0);
   const [lastId, setLastId] = useState(0);
   const ttl = 2000; //milliseconds
   const addMessages = (message, type)=>{
      let createdAt = DateLib.now('unix');
      let typeMess = typeof message;
      let id = lastId;
      if(typeMess === 'string'){
         id = id + 1;
         messages.current[id] = validMessage({id, message, type, createdAt, ttl});
      } else if(Array.isArray(message)){
         for(let m of message){
            id = id + 1;
            messages.current[id] = validMessage({id, message: m, type, createdAt, ttl});
         }
      }
      setLastId(id);
      setLastUpdate(createdAt);
  }
  
   const deleteItem = (key) =>{
      if(key in messages.current && delete messages.current[key]){
         let now = DateLib.now('unix');
         setLastUpdate(now);
         return true;
      }
      return false;
   }
   return <ContextMessages.Provider value={{
      messages: messages.current, 
      addMessages, 
      deleteItem
   }}>{children}</ContextMessages.Provider>
}
