import ApiModelWithAuth from "../../../models/ApiModelWithAuth";
import Formatter from "../../../models/Formatter";

export class ProfileFormatter extends Formatter{}
export default class ApiProfile extends ApiModelWithAuth{
    pathDef = 'authorized/common/profile';

    getParamsForUpdate({name, surname, middle_name, email, phone, password, password_confirmation, current_password}){
        let data = {};
        if(name) data['name'] = name;
        if(surname) data['surname'] = surname;
        if(middle_name) data['middle_name'] = middle_name;
        if(email) data['email'] = email;
        if(phone) data['phone'] = phone;
        if(password) data['password'] = password;
        if(password_confirmation) data['password_confirmation'] = password_confirmation;
        if(current_password) data['current_password'] = current_password;
        return data;
    }

    formatterItem = ProfileFormatter.Item;
}
