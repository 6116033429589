//Реакт
import React, { useState, useEffect } from 'react';
//Стили
import './styles.css';

const daysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
};

const getMonthData = (year, month) => {
    const firstDayOfMonth = (new Date(year, month, 1).getDay() + 6) % 7;
    const totalDaysInMonth = daysInMonth(year, month);
    const totalDaysInPrevMonth = daysInMonth(year, month - 1);
    const weeks = Math.ceil((firstDayOfMonth + totalDaysInMonth) / 7);
    const prevDate = new Date();
    prevDate.setDate(prevDate.getDate() - 1)

    let dayCounter = 1;
    let data = [];

    for (let i = 0; i < weeks; i++) {
        let week = [];
        for (let j = 0; j < 7; j++) {
            if (i === 0 && j < firstDayOfMonth) {
                week.push({
                    day: totalDaysInPrevMonth - firstDayOfMonth + j + 1,
                    month: month - 1,
                    year: year,
                    disabled: true
                });
            } else if (dayCounter > totalDaysInMonth) {
                week.push({
                    day: dayCounter - totalDaysInMonth,
                    month: month + 1,
                    year: year,
                    disabled: true
                });
                dayCounter++;
            } else if ( prevDate > new Date(year, month , dayCounter)) {
                week.push({
                    day: dayCounter,
                    month: month,
                    year: year,
                    disabled: true
                });
                dayCounter++;
            } else {
                week.push({
                    day: dayCounter,
                    month: month,
                    year: year,
                    disabled: false
                });
                dayCounter++;
            }
        }
        data.push(week);
    }

    return data;
};

const formatNumber = (num) => {
    return num < 10 ? `0${num}` : num;
};

const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split('.').map(Number);
    return { day, month: month - 1, year }; // month: month - 1 because month is 0-indexed
};

const monthNames = [
    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
];

export default function Calendar({ onSelect, initialData = null, className = '' }) {
    const [selectedDate, setSelectedDate] = useState(null);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());

    useEffect(() => {
        if (initialData) {
            const parsedDate = parseDate(initialData);
            setSelectedDate(parsedDate);
            setCurrentYear(parsedDate.year);
            setCurrentMonth(parsedDate.month);
        }
    }, [initialData]);

    const handleDateClick = (date) => {
        setSelectedDate(date);
        if (!date.disabled && onSelect) {
            const formattedDay = formatNumber(date.day);
            const formattedMonth = formatNumber(date.month + 1);
            onSelect(date ? `${formattedDay}.${formattedMonth}.${date.year}` : 'Выберите дату');
        }
    };

    const handlePrevMonth = () => {
        if (currentMonth === 0) {
            setCurrentMonth(11);
            setCurrentYear(currentYear - 1);
        } else {
            setCurrentMonth(currentMonth - 1);
        }
    };

    const handleNextMonth = () => {
        if (currentMonth === 11) {
            setCurrentMonth(0);
            setCurrentYear(currentYear + 1);
        } else {
            setCurrentMonth(currentMonth + 1);
        }
    };

    const monthData = getMonthData(currentYear, currentMonth);

    return (
        <div className={`calendar ${className}`}>
            <div className="calendar__header">
                <button className="calendar__header-button" onClick={handlePrevMonth}>&#x25C4;</button>
                <span className="calendar__header-date">{`${monthNames[currentMonth]} ${currentYear}`}</span>
                <button className="calendar__header-button" onClick={handleNextMonth}>&#x25BA;</button>
            </div>
            <table>
                <thead>
                    <tr className='calendar__days'>
                        <th className='calendar__days-item'>Пн</th>
                        <th className='calendar__days-item'>Вт</th>
                        <th className='calendar__days-item'>Ср</th>
                        <th className='calendar__days-item'>Чт</th>
                        <th className='calendar__days-item'>Пт</th>
                        <th className='calendar__days-item'>Сб</th>
                        <th className='calendar__days-item'>Вс</th>
                    </tr>
                </thead>
                <tbody>
                    {monthData.map((week, index) => (
                        <tr key={index}>
                            {week.map((day, index) => (
                                <td
                                    key={index}
                                    className={`day ${day.disabled ? 'out-of-month' : ''} ${selectedDate && selectedDate.day === day.day && selectedDate.month === day.month ? 'active' : 'inactive'}`}
                                    onClick={() => handleDateClick(day)}
                                >
                                    {day && day.day}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}