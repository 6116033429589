//Реакт
import React from "react";
//Material UI
import FormHelperText from '@mui/material/FormHelperText';

const getArray = (errors) => {
    let _errors = [];
    if(typeof errors == 'string') _errors.push(errors);
    else{
        Object.keys(errors).forEach(key =>{
            let items = getArray(errors[key]);
            _errors = [..._errors, ...items]; 
        })
    }
    return _errors;
}
export default function MessageErrors({errors, sx = {}, ...other}){
    let _errors = [];
    if(errors){
        if(typeof errors == 'string') _errors.push(errors);
        else{
            _errors = getArray(errors);
        }
    }
    return _errors.map((error, key)=>{
        return <FormHelperText 
            key={key} 
            sx={{color: "red", ...sx}}
            {...other}
        >{error}</FormHelperText>
    })
}