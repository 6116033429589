//Реакт
import React from "react";
//Стили
import "./styles.css";

export default function TableFilters({children, col = 1}){
    return <thead className="table__filters">
        <tr className="table__filters-row" >
            <td className="table__filters-col" colSpan={col}>
                {children}
            </td>
        </tr>
    </thead>
}