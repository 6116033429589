//Реакт
import React, { useState, useContext, useLayoutEffect } from "react";
//Библиотеки и Api
import ApiCategory from "../../../../../app/api/authorized/admin/content/ApiCategory";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputWrapper from "../../../../components/input/__wrapper";
import InputDatetime from "../../../../components/inputs/datetime/datetime";
//Стили
import './styles.css';

export default function CategoryShow({ ...other}){
  return <Page ApiModel={ApiCategory} { ...other}>
    <CategoryShowContent { ...other}/>
  </Page>
}

function CategoryShowContent({ ...other}){
  const contextPage = useContext(ContextPage);
  const contextContent = useContext(ContextContent);
  const [_id, set_id] = useState(null);
  const [_name, set_name] = useState('');
  const [_slug, set_slug] = useState(null);
  const [_created, set_created] = useState(null);
  const [_updated, set_updated] = useState(null);

  contextContent._dataToServer.current = {
    id: _id,
    name: _name,
    slug: _slug
  };
  useLayoutEffect(()=>{
    contextContent._handleSetData.current = (data) =>{
      set_id(data.id);
      set_name(data.name);
      set_slug(data.slug);
      set_created(data.created_at);
      set_updated(data.updated_at);
      contextPage.setTitle(`Категория:  ${data.name}`);
      contextContent.setTitle(`Категория:  ${data.name}`);
      contextPage.setName(data.name);
    };
    contextContent.doLoad();
  },[]);

  return <>
    {!contextContent.isCreate ?
    <InputWrapper>
      <InputText className="pages__show-input" value={_id} readOnly={true}>id</InputText>
    </InputWrapper> 
    : null }
    <InputWrapper>
      <InputText className="pages__show-input" value={_slug} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_slug(e.target.value)}>Слаг</InputText>
    </InputWrapper> 
    <InputWrapper>
      <InputText className="pages__show-input" value={_name} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_name(e.target.value)}>Категория</InputText>
    </InputWrapper>
    {!contextContent.isCreate ? 
      <InputWrapper>
        <InputDatetime className="pages__show-input" value={_created} readOnly={true}>Создан</InputDatetime>
      </InputWrapper> 
    : null }
    {!contextContent.isCreate ? 
      <InputWrapper>
        <InputDatetime className="pages__show-input" value={_updated} readOnly={true}>Изменен</InputDatetime>
      </InputWrapper> 
    : null }
  </>
}
