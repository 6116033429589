//Реакт
import React from "react";
//Компоненты
import Label from "../label";
import MessageError from "../../message/__errors";
//Стили
import '../styles.css';

export default function InputText({value = '', children, placeholder, errors, className = '', ...other}){
    if(placeholder === undefined) placeholder = children;
    return <>
        <Label>{children}</Label>
        <input
            {...other}
            type="text"
            value={value ? value : ''}
            className={`input ${className}`}
            placeholder={placeholder}
        />
        {errors ? <MessageError errors={errors} sx={{width: "100%"}}/> : null}
    </>
}