import ApiModel from './ApiModel'
import ApiAuth from "../api/public/system/ApiAuth";

export default class ApiModelWithAuth extends ApiModel{
    
    static isLoadRefresh = false;
    
    get headers() {
        if(this.isSendFile)
            return {
                // "Content-Type": "multipart/form-data",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`,
            };
        return {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${this.getAccessToken()}`,
        };
    }
    async _query(path, method, data, type = null, iRepeat = 0){
        this.setIsLoad(true);
        let result = await this.__query(path, method, data, type);
        
        if(result.code === 401){
            await new Promise(resolve => setTimeout(resolve, 1000));
            if(iRepeat <= 5)
                result = await this._query(path, method, data, type, (iRepeat+1));
            else{
                this.setIsLoad(false);
                await ApiAuth.Logout();
                return false;
            }
        }
        this.setIsLoad(false);
        return result;
    }

}
