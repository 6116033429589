import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";
import Formatter from "../../../../models/Formatter";

export class RoleFormatter extends Formatter{}
export default class ApiUser extends ApiModelWithAuth{
    pathDef = 'authorized/admin/user/roles';

    formatterItem = RoleFormatter.Item;
}

