import TypeConst from "./consts";

export default function todosReducer(state = {
    paymentStatuses: {},
    paymentTypes: {}
}, action) {
    switch (action.type) {
        case TypeConst.SET_PAYMENT_STATUSES:
            return {
                ...state,
                paymentStatuses: action.paymentStatuses
            };
        case TypeConst.SET_PAYMENT_TYPES:
            return {
                ...state,
                paymentTypes: action.paymentTypes
            };
        default:
            return state;
    }
}
