//Реакт
import React from "react";
//Стили
import "./styles.css";

function getItem({i, action, current}){
    return <div className={`list__pagination-item ${current === i ? 'current' : ''}`} key={i} onClick={()=>{action(i)}}>{i}</div>;
}
function getItemSkip({i}){
    return <div className="list__pagination-item-skip" key={i}>...</div>;
}

export default function ListPagination({current, last, action}){
    let left = current - 2;
    let right = current + 2;
    let arr = [];
    let isSkip = false;
    for (let i = 1; i <= last; i++) {
        let item = null;
        if(
            (i < left || i > right)
            && !( i === 1 || i === last)
        ){
            if(!isSkip) arr.push(getItemSkip({i}));
            isSkip = true;
            continue;
        }
        item = getItem({i, action, current}); 
        isSkip = false;
        if(item) arr.push(item);
    }
    return <div className="list__pagination">
        {arr}
    </div>
}