//Реакт
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
//Компоненты
import { ContextContent } from "../contentContext";
//Material UI
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import LogoDevIcon from '@mui/icons-material/LogoDev';
import { IconButton } from '@mui/material';
//Стили
import "./styles.css";

export default function ContentHeader({children, route, ...other}){
  const {checkPermission, type, setType, reSetData, title} = useContext(ContextContent);
  let viewDevtools = null;
  if(!children){
    if(type === 'show' 
      && (checkPermission('destroy') ||  checkPermission('edit'))){
      children = <IconButton 
          size="large"
          onClick={()=>setType('edit')}
          {...other}
      ><EditIcon fontSize="inherit"/></IconButton>
    }else if(type === 'edit'){
      children = <IconButton 
          size="large"
          onClick={()=>{
            setType('show');
            reSetData();
          }}
          {...other}
      ><CloseIcon fontSize="inherit"/></IconButton>
    }
  }

  return <div className={`content__header ${['show', 'edit'].includes(type) ? 'content__header-sticky' : ''}`}>
    <div className="content__header-left">
      { null }
    </div>
    <div className="content__header-rigth">
      {children}
      {viewDevtools}
    </div>
  </div>
}