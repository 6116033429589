//Реакт
import React, { useState } from "react";
//Компоненты
import Label from "../label";
import MessageError from "../../message/__errors";
import MyModal from "../../modals/modal/index";
//Material UI
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
//Стили
import './styles.css';

export default function InputTextArea({ children, value, placeholder,onChange, errors, className = '', ...other }) {
  if (placeholder === undefined) placeholder = children;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFullSize, setIsFullSize] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsFullSize(false);
  };

  const handleSizeChange = (() => {
    setIsFullSize(!isFullSize);
  }) 

  return (
    <>
      <Label>{children}</Label>
      <div className={`textarea__input_container  ${className}`}>
        <textarea
          value={value ? value : ''}
          {...other}
          className={`textarea__input input`}
          placeholder={placeholder}
          onChange={onChange}
        />
        <div className="textarea__button" onClick={openModal}>
          <ZoomOutMapOutlinedIcon className="textarea__button-icon"/>
        </div>
      </div>
      {errors ? <MessageError errors={errors} sx={{ width: "100%" }} /> : null}
      <MyModal className={`${isFullSize ? 'modal__size-full' : ''}`} isOpenModal={isModalOpen} closeModal={closeModal} title={children}>
      <div className="textarea__input_container-modal">
        <textarea
          value={value ? value : ''}
          {...other}
          className={`textarea__input-modal input ${className}`}
          placeholder={placeholder}
          onChange={onChange}
        />
        <div className="textarea__button-modal" onClick={handleSizeChange}>
          <AspectRatioOutlinedIcon className="textarea__button-icon"/>
        </div>
      </div>
      </MyModal>
    </>
  );
}