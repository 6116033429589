// Реакт
import React from "react";
// Импорт компонента Chart из react-google-charts
import { Chart } from "react-google-charts";
// Стили
import "./styles.css";

export default function UniversalChart({
    children,
    data,
    options,
    chartType = "LineChart",
    className = '',
    errors,
    containerStyle = { width: '100%', height: '600px' },
    ...other
}) {
    return (
        <div className={`chart-container ${className}`} {...other}>
            {errors && <div className="error">{errors}</div>}
            <Chart
                chartType={chartType}
                data={data}
                options={options}
                width={containerStyle.width}
                height={containerStyle.height}
            />
            {children}
        </div>
    );
}