import ApiModel from "../../../models/ApiModel";
import Formatter from "../../../models/Formatter";

export class CertificateProductFormatter extends Formatter{}
export default class ApiCertificateProducts extends ApiModel{
    pathDef = 'public/products/certificate-products';


    getParamsForStore({name, email, phone, productId, promocode}){
        let data = {};
        if(name) data['name'] = name;
        if(email) data['email'] = email ?? null;
        if(phone) data['phone'] = phone ?? null;
        if(promocode) data['promocode'] = promocode;
        return data;
    }
    formatterItem = CertificateProductFormatter.Item;
}

