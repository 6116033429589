//Реакт
import React, { useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
//Redux
import { useDispatch } from "react-redux";
import { setTitle as setTitleRedux } from "../../../app/reducers/app/actions";
//Библиотеки
import { Breadcrumbs } from "../../../inc/Breadcrumbs";
//Контент
import { ContextApp } from "../app/appContext";
import { ContextPage } from "./pageContext";

export function ProviderPage({ApiModel, route, routes, children, ...other}){
    const {checkPermission} = useContext(ContextApp);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [name, setName] = useState(other.name ? other.name : '');
    let { id } = useParams();
    let breadcrumb = route ? Breadcrumbs.for(route.name, {id, name}) : null;
    const goPageIndex = () =>{
        if (!('index' in routes)) return;
        let path = routes.index.url();
        navigate(path);
    };
    const goPageBack = () =>{
        if (!('index' in routes)) return;
        let path = routes.index.url();
        navigate(path);
    };
    const goPageShow = (id) =>{
        if(!('show' in routes)) return;
        let path = routes.show.url({id});
        navigate(path);
    };
    const goPageCreate = (id) =>{
        if(!('create' in routes)) return;
        let path = routes.create.url();
        navigate(path);
    };
    const setTitle = (title) => {
        dispatch(setTitleRedux(title));
    }
    const checkPermissionForPage = (name = null) =>{
        if(!route) return false;
        if(!name) return checkPermission(route.permission.name);
        return checkPermission(`${route.permission.prefix}${name}`);
    }
    return <ContextPage.Provider value={{
        route,
        goPageIndex,
        goPageBack,
        goPageShow,
        goPageCreate,
        setTitle,
        name, setName,
        breadcrumb,
        id,
        routes,
        checkPermission: checkPermissionForPage,
    }}>{children}</ContextPage.Provider>
}