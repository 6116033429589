//Реакт
import React from "react";
//Компоненты
import Modal from "../modal";
//Стили
import "./styles.css";

export default function ModalMessage({
    message,
    children,
    ...other
}){
    return (
        <Modal
            {...other}
        >
            {message}
            {children}
        </Modal>
    );
}