//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
//Библиотеки и Api
import ApiBalanceDiff from "../../../../../app/api/authorized/admin/booking/ApiBalanceDiff";
import { route } from "../../../../../inc/Routes";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
//Стили
import './styles.css';

export default function BalanceDiffIndex({ ...other }) {
    return <Page {...other}>
        <BalanceDiffIndexContent {...other} />
    </Page>
}

function BalanceDiffIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_type, set_type] = useState('');
    const [_fio, set_fio] = useState('');

    useLayoutEffect(() => {
        contextPage.setTitle(`Депозиты (разница)`);
        contextContent.setTitle(`Депозиты (разница)`);
    }, [contextContent, contextPage]);
    const header = [
        <ListHeaderItem
            key="id"
            name="id"
            isSort={false}
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="fio"
            name="fio"
            isSort={false}
        >
            Пользователь
        </ListHeaderItem>,
        <ListHeaderItem
            key="type"
            name="type"
            isSort={false}
        >
            Тип
        </ListHeaderItem>,
        <ListHeaderItem
            key="balance"
            name="balance"
            isSort={false}
        >
            Баланс
        </ListHeaderItem>,
        <ListHeaderItem
            key="transaction_balance"
            name="transaction_balance"
            isSort={false}
        >
            Баланс по транзакциям
        </ListHeaderItem>,
        <ListHeaderItem
            key="balance_difference"
            name="balance_difference"
            isSort={false}
        >
            Разница
        </ListHeaderItem>,
    ];

    const filters = [
        <InputText className="input_wf" value={_fio} onChange={(e) => { set_fio(e.target.value) }}>Пользователь</InputText>,
        <InputNumber className="input_wf" value={_type} onChange={(e) => { set_type(e.target.value) }}>Тип</InputNumber>,
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-balance-diff_column-width" key="id" data-label={header[0].props.children}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-balance-diff_column-width" key="fio" data-label={header[1].props.children}><a href={route(`authorized.admin.user.users.show`,{id: row.user_id})}>{row.user_fio}</a></ListBodyItem>,
            <ListBodyItem className="admin-balance-diff_column-width" key="type" data-label={header[2].props.children}>{row.type_name}</ListBodyItem>,
            <ListBodyItem className="admin-balance-diff_column-width" key="balance" data-label={header[3].props.children}>{row.balance}</ListBodyItem>,
            <ListBodyItem className="admin-balance-diff_column-width" key="transaction_balance" data-label={header[4].props.children}>{row.balance}</ListBodyItem>,
            <ListBodyItem className="admin-balance-diff_column-width" key="balance_difference" data-label={header[5].props.children}>{row.balance}</ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiBalanceDiff}
            renderRow={renderRow}
            searchParams={{ user_fio: _fio, type: _type }}
        />
    </>
}
