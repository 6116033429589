//Реакт
import React from "react";
import { useNavigate  } from "react-router-dom";
//Material UI
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
//Стили
import "./styles.css";

export default function ButtonLink({children, href, onClick, className = '', ...other}){
    const navigate = useNavigate();
    const actionOnClick = (e) => {
        e.preventDefault();
        if(href) navigate(href);
        if(onClick) onClick();
    }
    if(!children) children = <OpenInNewIcon/>
    return <a
        {...other}
        href={href}
        className={`button-link ${className}`}
        onClick={actionOnClick}
    >{children}</a>
}