import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";
import Formatter from "../../../../models/Formatter";

export class OrderBookingFormatter extends Formatter {
  static CustomItem(data) {
    return {
      date_pay: this.Date(data.date_pay),
    };
  }
}
export default class ApiOrderBookings extends ApiModelWithAuth {
  pathDef = 'authorized/admin/order/bookings';

  formatterItem = OrderBookingFormatter.Item;
}