//Реакт
import React, { useContext, useState } from "react";
//Компоненты
import Button from "../../../components/button";
import ModalYesNo from "../../../components/modals/modal-yes-no";
import { ContextContent } from "../contentContext";
//Стили
import "./styles.css";

export default function ContentFooter({children, ...other}){
  const [isModalDelete, setIsModalDelete] = useState(false);
  const contextContent = useContext(ContextContent);
  if(!children){
    if(contextContent.type === 'edit'){
      children = <>
      <div className="content__footer-left">
        {contextContent.checkPermission('destroy') ? <Button onClick={()=>setIsModalDelete(true)} className="btn-danger" >Удалить</Button> : null}
      </div>
      <div className="content__footer-rigth">
        {contextContent.checkPermission('edit') ? <Button onClick={contextContent.doUpdate} className="btn-success">Сохранить</Button> : null}
      </div>
      <ModalYesNo 
        isOpenModal={isModalDelete} 
        closeModal={()=>setIsModalDelete(false)}
        actionBtnNo={()=>setIsModalDelete(false)}
        actionBtnYes={()=>{
          contextContent.doDelete();
          setIsModalDelete(false);
        }}
        title={`Удалить ${contextContent.title.toLocaleLowerCase()}?`}
      />
      </>
    }else if(contextContent.type === 'create' || contextContent.type === 'create-child'){
      children = <>
      <div className="content__footer-left"></div>
      <div className="content__footer-rigth">
        {contextContent.checkPermission('create') ? <Button onClick={contextContent.doCreate} className="btn-success">Создать</Button> : null}
      </div>
      </>
    }
  }
  return <div className="content__footer">
      {children}
  </div>
}