
const day = 1000*60*60*24;

export default class DateLib{
    static formatterDate(date, opt='en'){
        if(!date) return null;
        if(typeof date === 'string') date = new Date(date);
        if(!this.isValidDate(date)) return null;
        let day = date.toLocaleString("default", {day: '2-digit'});
        let month = date.toLocaleString("default", {month: '2-digit'});
        let year = date.toLocaleString("default", {year: 'numeric'});
        return opt === 'ru'
        ? `${day}.${month}.${year}` : `${year}-${month}-${day}`;
    }
    static formatterTime(date){
        if(!date) return null;
        if(typeof date == 'string') return date;
        return date.toLocaleString("ru", {
            hour: 'numeric',
            minute: 'numeric',
          })
    }
    static formatterDateTime(date, opt='ru') {
        if (!date) return null;
        if (typeof date === 'string') return date;
        
        if(opt === 'unix') return date.getTime();
        
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        
        return opt === 'ru'
          ? `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
          : `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      }
    static getDayWeek(date){
        if(!date) return null;
        if(typeof date === 'string') date = new Date(date);
        switch(date.getDay()){
            case 0: return "Воскресенье";
            case 1: return "Понедельник";
            case 2: return "Вторник";
            case 3: return "Среда";
            case 4: return "Четверг";
            case 5: return "Пятница";
            case 6: return "Суббота";
            default: return null;
        }
    }
    static equals(date1, date2){
        return (DateLib.formatterDate(date1) === DateLib.formatterDate(date2))
    }
    static isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }
    static get day(){
        return day;
    }
    static now(type = 'en'){
        return DateLib.formatterDateTime(new Date(), type);
    }
}
