//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import DateLib from "../../../../../inc/DateLib";
import ApiOrderCompetitions from "../../../../../app/api/authorized/admin/order/ApiOrderCompetitions";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import InputPhone from "../../../../components/inputs/phone/phone";
import Select from "../../../../components/inputs/select";
import ButtonLinkShow from "../../../../components/button-link/__show";
//Стили
import './styles.css';

export default function OrderCompetitionIndex({ ...other }) {
    return <Page {...other}>
        <OrderCompetitionIndexContent {...other} />
    </Page>
}

function OrderCompetitionIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_id, set_id] = useState('');
    const [_name, set_name] = useState('');
    const [_competition, set_competition] = useState('');
    const [_phone, set_phone] = useState('');
    const [_email, set_email] = useState('');
    const [_price, set_price] = useState('');
    const [_productId, set_productId] = useState('');
    const [_promocodeName, set_promocodeName] = useState('');
    

    const competitions = useSelector(state => state.adminOption.competitions);

    const handleCompetitionChange = (event) => {
        set_competition(event.target.value);
    };

    useLayoutEffect(() => {
        contextPage.setTitle(`Соревнования`);
        contextContent.setTitle(`Соревнования`);
    }, [contextContent, contextPage]);

    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="competitionTeamName"
            name="competitionTeamName"
        >
            Команда
        </ListHeaderItem>,
        <ListHeaderItem
            key="show"
            name="show"
        >
            Участники
        </ListHeaderItem>,
        <ListHeaderItem
            key="competitionTeamId"
            name="competitionTeamId"
            isSort={false}
        >
            Номер команды
        </ListHeaderItem>,
        <ListHeaderItem
            key="competitionTeamCity"
            name="competitionTeamCity"
        >
            Город
        </ListHeaderItem>,
        <ListHeaderItem
            key="competition"
            name="competition"
            isSort={false}
        >
            Соревнование
        </ListHeaderItem>,
        <ListHeaderItem
            key="competitionTeamDiscipline"
            name="competitionTeamDiscipline"
        >
            Дисциплина
        </ListHeaderItem>,
        <ListHeaderItem
            key="phone"
            name="phone"
        >
            Телефон
        </ListHeaderItem>,
        <ListHeaderItem
            key="email"
            name="email"
        >
            Email
        </ListHeaderItem>,
        <ListHeaderItem
            key="price"
            name="price"
        >
            Итого, руб
        </ListHeaderItem>,
        <ListHeaderItem
            key="product"
            name="product"
            isSort={false}
        >
            Продукт
        </ListHeaderItem>,
        <ListHeaderItem
            key="promocode"
            name="promocode"
        >
            Промокод
        </ListHeaderItem>,
        <ListHeaderItem
            key="status"
            name="status"
        >
            Статус
        </ListHeaderItem>,
        <ListHeaderItem
            key="created_at"
            name="created_at"
        >
            Создан
        </ListHeaderItem>,
        <ListHeaderItem
            key="updated_at"
            name="updated_at"
        >
            Изменен
        </ListHeaderItem>,
        // <ListHeaderItem
        //     key="create"
        //     name="create"
        //     isSort={false}
        // >
        //     {contextContent.checkPermission('create') ? <ButtonLinkAdd href={contextPage.routes.create.url()} /> : null}
        // </ListHeaderItem>,
    ];

    const filters = [
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>id</InputNumber>,
        <InputText className="input_wf" value={_name} onChange={(e) => { set_name(e.target.value) }}>Команда</InputText>,
        <InputPhone className="input_wf" value={_phone} onChange={(e) => { set_phone(e.target.value) }}>Телефон</InputPhone>,
        <Select className="input_wf" options={competitions} value={_competition} onChange={handleCompetitionChange}>Соревнование</Select>,
        <InputText className="input_wf" value={_email} onChange={(e) => { set_email(e.target.value) }}>Email</InputText>,
        <InputNumber className="input_wf" value={_productId} onChange={(e) => { set_productId(e.target.value) }}>Продукт</InputNumber>,
        <InputText className="input_wf" value={_promocodeName} onChange={(e) => { set_promocodeName(e.target.value) }}>Промокод</InputText>
    ];
    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-competitions_column-width" key="id" data-label={header[0].props.children}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="competitionTeamName" data-label={header[1].props.children}>
               <a target="_blank" href={row.team_show_url}>{row.team}</a>
            </ListBodyItem>,
            <ListBodyItem key="show">
                {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.teamShow.url({ id: row.team_id })} /> : null}
            </ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="competitionTeamId" data-label={header[2].props.children}>{row.team_id}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="competitionTeamCity" data-label={header[3].props.children}>{row.city}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="competition" data-label={header[4].props.children}>{row.competition}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="competitionTeamDiscipline" data-label={header[5].props.children}>{row.discipline}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="phone" data-label={header[6].props.children}>{row.phone}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="email" data-label={header[7].props.children}>{row.email}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="price" data-label={header[8].props.children}>{row.total} ₽</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="product" data-label={header[9].props.children}>
                {row.products.map((product) => (
                    <div key={product.id}>
                        <div>Время: {product.minutes}</div>
                        <div>Цена: {product.price} ₽ ({product.total} ₽)</div>
                    </div>
                ))}
            </ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="promocode" data-label={header[10].props.children}>{row.promocode_name}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="status" data-label={header[11].props.children}>{row.status}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="created_at" data-label={header[12].props.children}>{DateLib.formatterDateTime(row.created_at)}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="updated_at" data-label={header[13].props.children}>{DateLib.formatterDateTime(row.updated_at)}</ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiOrderCompetitions}
            renderRow={renderRow}
            searchParams={{ id: _id, name: _name, competition: _competition, phone: _phone, email: _email, price: _price, product: _productId, promocode: _promocodeName }}
        />
    </>
}
