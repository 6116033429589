//Реакт
import React, { useState, useRef, useContext } from "react";
//Компоненты
import { ContextContent } from "./contentContext";
import { ContextApp } from "../app/appContext";

function getDiffData(oldData, newData){
    if(!oldData) return newData;
    let data = {};
    for(let key in newData){
        let oldValue = (key in oldData) ? oldData[key] : undefined;
        //проверка на файл
        let newValue = (key in newData) ? newData[key] : undefined;
        if(oldValue === undefined && newValue !== undefined) {
            data[key] = newValue;
        } else if(newValue !== undefined && oldValue !== newValue) {
            data[key] = newValue;
        }
    }
    return data;
}

export function ProviderContent({
        ApiModel, children, id, devtools, checkPermission, data,
        handleDoCreate = null, handleDoUpdate = null, handleDoDelete = null,
        ...other
    }){
    const { messages } = useContext(ContextApp);
    const [type, setType] = useState(other.type ? other.type : '');
    const [showNoId, setShowNoId] = useState(other.showNoId ? other.showNoId : false);
    const [title, setTitle] = useState(other.title ? other.title : '');
    const [isLoad, setIsLoad] = useState(false);
    const [codeApi, setCodeApi] = useState(null);
    const _dataFromServer = useRef(null);
    const _dataToServer = useRef(null);
    const _handleSetData = useRef(null);
    const isShow = (type === "show") ? true : false;
    const isEdit = (type === "edit") ? true : false;
    const isCreate = (type === "create") ? true : false;
    const isCreateChild = (type === "create-child") ? true : false;
    const doLoad = async () =>{
        if(!id && !showNoId) return;
        setIsLoad(true);
        const apiShow = ApiModel.Show(id); 
        let data = await apiShow.getResponse();
        setCodeApi(apiShow.code);
        if(data){
            _dataFromServer.current = data;
            _handleSetData.current(data);
        }
        setIsLoad(false);
        return data;
    }
    const setFromData = async () =>{
        if(data){
            _dataFromServer.current = data;
            _handleSetData.current(data);
        }
        return data;
    }
    const doUpdate = async () =>{
        if(!(!showNoId ? id : true && _dataToServer.current)) return;
        let dataToUpdate = getDiffData(_dataFromServer.current, _dataToServer.current);
        if(Object.keys(dataToUpdate).length === 0) {
            messages.addMessages('Ничего не поменялось', 'warning');
            return;
        }
        setIsLoad(true);
        // const apiUpdate = ApiModel.Update(id, _dataToServer.current);
        const apiUpdate = ApiModel.Update(id, dataToUpdate);
        let data = await apiUpdate.getResponse();

        if(data){
            _dataFromServer.current = data;
            _handleSetData.current(data);
            if(handleDoUpdate) handleDoUpdate(data);
        }

        setIsLoad(false);    

        return data;
    };
    const doCreate = async () =>{
        if(!_dataToServer.current) return;
        setIsLoad(true);
        const apiStore = ApiModel.Store(_dataToServer.current);
        let data = await apiStore.getResponse();
        if(data){
            if('id' in data){
                setType('show');
                _handleSetData.current(data); 
            }
            if(handleDoCreate) handleDoCreate(data);
        }
        setIsLoad(false);
        return data;
    };
    const doDelete = async () =>{
        if(!id) return;
        setIsLoad(true);
        const apiDestroy = ApiModel.Destroy(id);
        let data = await apiDestroy.getResponse();
        if(data){
            if(handleDoDelete) handleDoDelete(data);
        }else{
            //error
        }
        setIsLoad(false);
        return data;
    };
    const reSetData = () =>{
        if(!(_dataToServer.current && _handleSetData.current)) return null;
        _handleSetData.current(_dataFromServer.current);
    }
    /*
    useLayoutEffect(()=>{
        setType(other.type);
        setTitle('');
    }, [other.type]);
    */
    return <ContextContent.Provider value={{
        id,
        isLoad, setIsLoad,
        type, setType,
        title, setTitle,
        isShow,
        isEdit,
        isCreate,
        isCreateChild,
        isCreateOrEdit: (isEdit || isCreate || isCreateChild),
        isShowOrEdit: (isShow || isEdit),
        devtools,
        checkPermission,
        doLoad: (data) ? setFromData : doLoad,
        doCreate,
        doUpdate,
        doDelete,
        reSetData,
        codeApi,
        _dataFromServer,
        _dataToServer,
        _handleSetData,

    }}>{children}</ContextContent.Provider>
}