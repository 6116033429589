import ApiModel from "../../../../models/ApiModel";
import Formatter from "../../../../models/Formatter";

export class BookingByCertificateFormatter extends Formatter{}
export default class ApiBookingByCertificate extends ApiModel{
    pathDef = 'public/booking/book-by-certificate';
    __subtype = "searchParams";

    getParamsForStore({name, email, phone, date, timeId, certificate}){
        let data = {};
        if(name) data['user[name]'] = name;
        if(email) data['user[email]'] = email;
        if(phone) data['user[phone]'] = phone;
        if(timeId) data['slotTime[timeId]'] = timeId;
        if(date) data['slotTime[date]'] = date;
        if(certificate) data['certificate[code]'] = certificate;
        return data;
    }

    formatterItem = BookingByCertificateFormatter.Item;
}

