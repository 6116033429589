const consts = {
    SET_TITLE:                      "APP-SET_TITLE",
    SET_ERROR:                      "APP-SET_ERROR",
    SET_STATUS_LOAD:                "APP-SET_STATUS_LOAD",
    SET_IS_AUTH:                    "APP-SET_IS_AUTH",
    SET_ACCESS_TOKEN:               "APP-SET_ACCESS_TOKEN",
    SET_REFRESH_TOKEN:              "APP-SET_REFRESH_TOKEN",
    SET_EXP_ACCESS_TOKEN:           "APP-SET_EXP_ACCESS_TOKEN",
    SET_EXP_REFRESH_TOKEN:          "APP-SET_EXP_REFRESH_TOKEN",
    SET_MENU:                       "APP-SET_MENU",
    SET_FILTER:                     "APP-SET_FILTER",
    SET_LANG:                       "APP-SET_LANG",
    SET_USER_PERMISSIONS:           "APP-SET_USER_PERMISSIONS",
    UPDATE_MENU_GROUP_STATE:        "APP-UPDATE_MENU_GROUP_STATE",
};
  
export default consts;
