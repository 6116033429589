import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";
import Formatter from "../../../../models/Formatter";

export class PromocodeFormatter extends Formatter{
    static CustomItem(data) {
        return {
          date_start: this.Date(data.date_start),
          date_end: this.Date(data.date_end),
        }
    }
}
export default class ApiPromocode extends ApiModelWithAuth{
    pathDef = 'authorized/admin/content/promocodes';

    formatterItem = PromocodeFormatter.Item;
}

