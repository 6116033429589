export default class FileLib{
    static getExtension(fileName){
        if(!fileName) return null;
        if (fileName.startsWith('data:image/')){ // 1. Проверка на наличие "data:image/..." в строке
            const parts = fileName.split(';');
            const mimeParts = parts[0].split('/');  //Разделение первого элемента (mime-тип) по "/"
            return mimeParts[1];  //Возврат второго элемента (расширение)
        }else{
        const items = fileName.split('.');  // 2. Обработка обычного URL
        return items[items.length - 1];
}
    }
    static getName(fileName){
        if(!fileName) return null;
        let extension = FileLib.getExtension(fileName);
        let nameChars = fileName.length - extension.length -1;
        if(nameChars <= 0) return null;
        return fileName.substring(-1, nameChars);
    }
    static replaceExtension(fileName, newFileName){
        let name = FileLib.getName(fileName);
        let extension = FileLib.getExtension(newFileName);
        if(name && extension) 
            return `${name}.${extension}`;
        return null;

    }
}