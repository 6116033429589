import ApiModel from "../../../../models/ApiModel";

export default class ApiCertificateCheck extends ApiModel{
    //formatterItem = formatterOrder;
    paths = {
        store: "public/booking/certificate/check",
    }

    getParamsForStore({code}){
        let data = {};
        if(code) data['code'] = code;
        return data;
    }
}