//реакт
import React, { useState, useLayoutEffect, useRef } from 'react';
//Quill
import ReactQuill, { Quill } from 'react-quill';
//Стили
import 'react-quill/dist/quill.snow.css';
import './styles.css'

//компоненты
import Label from "../inputs/label";
import MessageError from "../message/__errors";


export default function ContentEditor({value = '', defaultContent = null, children, maxLength, placeholder, errors, className = '', content = null, onChange, readOnly, ...other}) {
  const [editorContent, setEditorContent] = useState('');
  const quillRef = useRef(null);
  const [_warning, setWarning]= useState(null);
  

  useLayoutEffect(() => {
    if (defaultContent) {
      setEditorContent(defaultContent);
    }
  }, [defaultContent]);

  const Inline = Quill.import('blots/inline');
  const Block = Quill.import('blots/block');
  class BoldBlot extends Inline {
    static blotName = 'bold';
    static tagName = 'b';
  }
  class AlignBlot extends Block {
    static blotName = 'align';
    static tagName = 'div';
    
    static formats(domNode){
      return domNode.className;
    }
    static create(value) {
      const node = super.create(value);
      node.setAttribute('class', value);
      return node;
    }
  }
  class FontSizeBlot extends Inline {
    static blotName = 'size';
    static tagName = 'p';
    

    static formats(domNode) {
      return domNode.className;
    }

    static create(value) {
      if(value === 'large') value = 'f-s_lg';
      if(value === 'small') value = 'f-s_sm';
      if(value === 'huge') value = 'f-s_hg';
      if(value === false) value = 'f-s_nm';
      const node = super.create(value);
      node.setAttribute('class', value);
      return node;
    }
  }

  Quill.register('formats/size', FontSizeBlot);
  Quill.register('formats/align', AlignBlot);
  Quill.register('formats/bold', BoldBlot);


  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, false] }],
      ['bold', 'italic', 'underline','strike'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      [{ 'align': [] }],
      [{'color': []}],
      ['link'],
      ['clean'],
      [{ 'font': [] }, { 'size':  ['small', false, 'large', 'huge', ] }],
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet', 'indent',
    'align',
    'color',
    'link',
    'font', 'size'
  ];

  const handleChange = (editorContent) => {
    setEditorContent(editorContent);
    const length = editorContent.length ? editorContent.length : 0;
    if(length < maxLength){
      setWarning(null);
      if(editorContent != '<p><br></p>')
        onChange({ target: { value: editorContent } });
      else  onChange({ target: { value: null } });
    }
    else setWarning('Превышен лимит контента!');
  }

  return <>
      <Label>{children}</Label>
      <div className="editor-wrapper" > 
        <ReactQuill 
        ref={quillRef}
        readOnly={readOnly}
        theme="snow" 
        value={editorContent} 
        onChange={handleChange}
        modules={modules}
        formats={formats}
        isControlled
        />
        <Label className="label__content-length">{editorContent.length ? editorContent.length
        + '/' + maxLength : '0/' + maxLength}</Label>
        <Label className="label__warning">{_warning}</Label>
      </div>
      {errors ? <MessageError errors={errors} sx={{width: "100%"}}/> : null}
      {/* <div>
        {editorContent}
      </div> */}
  </>
}