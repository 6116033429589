//Реакт
import React, { useState, useContext, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiPromocode from "../../../../../app/api/authorized/admin/content/ApiPromocode";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number"
import InputWrapper from "../../../../components/input/__wrapper";
import InputDatetime from "../../../../components/inputs/datetime/datetime";
import InputTextArea from "../../../../components/inputs/textarea/textarea";
import InputCheckbox from "../../../../components/inputs/checkbox/checkbox";
import CheckboxBlock from "../../../../components/checkbox-block";
import Select from "../../../../components/inputs/select";
//Стили
import './styles.css';

export default function PromocodeShow({ ...other }) {
    return <Page ApiModel={ApiPromocode} {...other}>
        <PromocodeShowContent {...other} />
    </Page>
}

function PromocodeShowContent({ ...other }) {
    const contextPage = useContext(ContextPage);
    const contextContent = useContext(ContextContent);
    const [_id, set_id] = useState(null);
    const [_name, set_name] = useState('');
    const [_code, set_code] = useState('');
    const [_description, set_description] = useState('');
    const [_discountPercent, set_discountPercent] = useState(null);
    const [_amountUse, set_amountUse] = useState(null);
    const [_dateStart, set_dateStart] = useState(null);
    const [_dateEnd, set_dateEnd] = useState(null);
    const [_allowedProducts, set_allowedProducts] = useState([]);
    const [_orderTypes, set_orderTypes] = useState([]);
    const [_extraProductId, set_extraProductId] = useState(null);
    const [_isActive, set_isActive] = useState(0);
    const [_platformId, set_platformId] = useState(null);
    const [_createdBy, set_createdBy] = useState('');
    const [_updatedBy, set_updatedBy] = useState('');
    const [_created, set_created] = useState(null);
    const [_updated, set_updated] = useState(null);

    const platforms = useSelector(state => state.adminOption.platforms);
    const products = useSelector(state => state.adminOption.products);
    const orderTypes = useSelector(state => state.adminOption.orderTypes);

    const handleSetSelectedProducts = (updatedItems) => {
        set_allowedProducts(updatedItems);
    };

    const handleSetSelectedOrderTypes = (updatedItems) => {
        set_orderTypes(updatedItems);
    };

    const handlePlatformChange = (event) => {
        set_platformId(event.target.value);
    };

    const handleExtraProductChange = (event) => {
        set_extraProductId(event.target.value);
    }

    const handleIsActiveChange = () => {
        set_isActive(Number(!_isActive));
      }

    contextContent._dataToServer.current = {
        name: _name,
        code: _code,
        description: _description,
        date_start: _dateStart,
        date_end: _dateEnd,
        is_active: _isActive,
        products: _allowedProducts,
        amount_use: _amountUse,
        platform_id: _platformId,
        order_types: _orderTypes,
        extra_product_id: _extraProductId,
        discount: _discountPercent,
    };
    useLayoutEffect(() => {
        contextContent._handleSetData.current = (data) => {
            set_id(data.id);
            set_name(data.name);
            set_code(data.code);
            set_description(data.description);
            set_dateStart(data.date_start);
            set_dateEnd(data.date_end);
            set_isActive(data.is_active);
            set_allowedProducts(data.products_allowed);
            set_amountUse(data.amount_use);
            set_platformId(data.platform_id);
            set_orderTypes(data.order_types);
            set_extraProductId(data.extra_product_id);
            set_discountPercent(data.discount_percent);
            set_createdBy(data.creator);
            set_updatedBy(data.updator);
            set_created(data.created_at);
            set_updated(data.updated_at);
            contextPage.setTitle(`Промокод:  ${data.name}`);
            contextContent.setTitle(`Промокод:  ${data.name}`);
            contextPage.setName(data.name);

        };
        contextContent.doLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
        {!contextContent.isCreate ?
            <InputWrapper>
                <InputText className="pages__show-input" value={_id} readOnly={true}>id</InputText>
            </InputWrapper>
            : null}
        <InputWrapper>
            <InputText className="pages__show-input" value={_name} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_name(e.target.value)}>Имя</InputText>
        </InputWrapper>
        <InputWrapper>
            <InputText className="pages__show-input" value={_code} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_code(e.target.value)}>Код</InputText>
        </InputWrapper>
        <InputWrapper>
            <InputTextArea className="pages__show-input" value={_description} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_description(e.target.value)}>Описание</InputTextArea>
        </InputWrapper>
        <InputWrapper>
            <InputDatetime className="pages__show-input" value={_dateStart} readOnly={!contextContent.isCreateOrEdit} onChange={(e) => { set_dateStart(e.target.value) }}>Дата начала</InputDatetime>
        </InputWrapper>
        <InputWrapper>
            <InputDatetime className="pages__show-input" value={_dateEnd} readOnly={!contextContent.isCreateOrEdit} onChange={(e) => { set_dateEnd(e.target.value) }}>Дата окончания</InputDatetime>
        </InputWrapper>
        <InputWrapper>
            <CheckboxBlock className="pages__show-input" readOnly={!contextContent.isCreateOrEdit} data={products} selectedItems={_allowedProducts} setSelectedItems={handleSetSelectedProducts}>Доступ для продуктов:</CheckboxBlock>
        </InputWrapper>
        <InputWrapper>
            <InputNumber className="pages__show-input" value={_amountUse} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_amountUse(e.target.value)}>Применений</InputNumber>
        </InputWrapper>
        <InputWrapper>
            <Select className="pages__show-input" options={platforms} value={_platformId} readOnly={!contextContent.isCreateOrEdit} onChange={handlePlatformChange}>Площадка</Select>
        </InputWrapper>
        <InputWrapper>
            <CheckboxBlock className="pages__show-input" readOnly={!contextContent.isCreateOrEdit} data={orderTypes} selectedItems={_orderTypes} setSelectedItems={handleSetSelectedOrderTypes}>Доступ для типов заказов:</CheckboxBlock>
        </InputWrapper>
        <InputWrapper>
            <Select className="pages__show-input" options={products} value={_extraProductId} readOnly={!contextContent.isCreateOrEdit} onChange={handleExtraProductChange}>Доп. продукт</Select>
        </InputWrapper>
        <InputWrapper>
            <InputNumber className="pages__show-input" value={_discountPercent} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_discountPercent(e.target.value)}>Скидка %</InputNumber>
        </InputWrapper>
        <InputWrapper>
            <InputCheckbox className="pages__show-input" value={_isActive} readOnly={!contextContent.isCreateOrEdit} onChange={handleIsActiveChange}>Активный</InputCheckbox>
        </InputWrapper>
        {!contextContent.isCreate ?
            <InputWrapper>
                <InputText className="pages__show-input" value={_createdBy} readOnly={true}>Создан</InputText>
            </InputWrapper>
        : null}
        {!contextContent.isCreate ?
            <InputWrapper>
                <InputText className="pages__show-input" placeholder={'Не изменен'} value={_updatedBy} readOnly={true}>Изменен</InputText>
            </InputWrapper>
        : null}
        {!contextContent.isCreate ?
            <InputWrapper>
                <InputDatetime className="pages__show-input" value={_created} readOnly={true}>Создан</InputDatetime>
            </InputWrapper>
        : null}
        {!contextContent.isCreate ?
            <InputWrapper>
                <InputDatetime className="pages__show-input" value={_updated} readOnly={true}>Изменен</InputDatetime>
            </InputWrapper>
        : null}
    </>
}
