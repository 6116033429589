//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import DateLib from "../../../../../inc/DateLib";
import ApiBanner from "../../../../../app/api/authorized/admin/event/ApiBanner";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import Select from "../../../../components/inputs/select";
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import ButtonLinkShow from "../../../../components/button-link/__show";
import ButtonLinkAdd from "../../../../components/button-link/__add";
//Material UI
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
//Стили
import './styles.css';

export default function BannerIndex({ ...other }) {
    return <Page {...other}>
        <BannerIndexContent {...other} />
    </Page>
}

function BannerIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_id, set_id] = useState('');
    const [_name, set_name] = useState('');
    const [_type, set_type] = useState('');
    const [_priority, set_priority] = useState('');
    const [_status, set_status] = useState('');
    const [_path, set_path] = useState('');
    const [_page, set_page] = useState('');

    const bannerTypes = useSelector(state => state.adminOption.bannerTypes);
    const bannerStatuses = useSelector(state => state.adminOption.bannerStatuses);
    
    const handleTypeChange = (event) => {
        set_type(event.target.value);
    };

    const handleStatusChange = (event) => {
        set_status(event.target.value);
    };

    useLayoutEffect(() => {
        contextPage.setTitle(`Баннеры`);
        contextContent.setTitle(`Баннеры`);
    }, [contextContent, contextPage]);
    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="name"
            name="name"
        >
            Название
        </ListHeaderItem>,
        <ListHeaderItem
            key="type_id"
            name="type_id"
        >
            Тип
        </ListHeaderItem>,
        <ListHeaderItem
            key="priority"
            name="priority"
        >
            Приоритет
        </ListHeaderItem>,
        <ListHeaderItem
            key="is_active"
            name="is_active"
        >
            Статус
        </ListHeaderItem>,
        <ListHeaderItem
            key="path"
            name="path"
            isSort={false}
        >
            Путь
        </ListHeaderItem>,
        <ListHeaderItem
            key="is_new_tab"
            name="is_new_tab"
        >
            Вкладка
        </ListHeaderItem>,
        <ListHeaderItem
            key="url_page"
            name="url_page"
        >
            Страница
        </ListHeaderItem>,
        <ListHeaderItem
            key="created_at"
            name="created_at"
        >
            Создана
        </ListHeaderItem>,
        <ListHeaderItem
            key="updated_at"
            name="updated_at"
        >
            Изменена
        </ListHeaderItem>,
        <ListHeaderItem
            key="create"
            name="create"
            isSort={false}
        >
            {contextContent.checkPermission('create') ? <ButtonLinkAdd href={contextPage.routes.create.url()} /> : null}
        </ListHeaderItem>,
    ];

    const filters = [
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>id</InputNumber>,
        <InputText className="input_wf" value={_name} onChange={(e) => { set_name(e.target.value) }}>Название</InputText>,
        <Select className="input_wf" options={bannerTypes} value={_type} onChange={handleTypeChange}>Тип</Select>,
        <InputNumber className="input_wf" value={_priority} onChange={(e) => { set_priority(e.target.value) }}>Приоритет</InputNumber>,
        <Select className="input_wf" options={bannerStatuses} value={_status} onChange={handleStatusChange}>Статус</Select>,
        <InputText className="input_wf" value={_path} onChange={(e) => { set_path(e.target.value) }}>Путь</InputText>,
        <InputText className="input_wf" value={_page} onChange={(e) => { set_page(e.target.value) }}>Страница</InputText>,
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-banners_column-width" key="id" data-label={header[0].props.children}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-banners_column-width" key="name" data-label={header[1].props.children}>{row.name}</ListBodyItem>,
            <ListBodyItem className="admin-banners_column-width" key="type_id" data-label={header[2].props.children}>{row.type_name}</ListBodyItem>,
            <ListBodyItem className="admin-banners_column-width"  key="priority" data-label={header[3].props.children}>{row.priority}</ListBodyItem>,
            <ListBodyItem className="admin-banners_column-width"  key="is_active" data-label={header[4].props.children}>{row.is_active ? 'Включен' : 'Выключенный'}</ListBodyItem>,
            <ListBodyItem className="admin-banners_column-width"  key="path" data-label={header[5].props.children}>{row.url_file}</ListBodyItem>,
            <ListBodyItem className="admin-banners_column-width"  key="is_new_tab" data-label={header[6].props.children}>
                      {
                    row.is_active ? <div className="tab_icon--success"><VerifiedIcon/></div>
                    : <div className="tab_icon--danger"><NewReleasesIcon/></div> 
                }
            </ListBodyItem>,
            <ListBodyItem className="admin-banners_column-width"  key="url_page" data-label={header[7].props.children}>{row.url_page}</ListBodyItem>,
            <ListBodyItem className="admin-banners_column-width"  key="created_at" data-label={header[8].props.children}>{DateLib.formatterDateTime(row.created_at)}</ListBodyItem>,
            <ListBodyItem className="admin-banners_column-width"  key="updated_at" data-label={header[9].props.children}>{DateLib.formatterDateTime(row.updated_at)}</ListBodyItem>,
            <ListBodyItem className="admin-banners_column-width"  key="show">
                {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.show.url({ id: row.id })} /> : null}
            </ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiBanner}
            tableClass={'banners'}
            renderRow={renderRow}
            searchParams={{ id: _id, name: _name, type: _type, priority: _priority, status: _status, path:_path, pageUrl: _page }}
        />
    </>
}
