import { route } from "./Routes";
export class Breadcrumbs {
    name = '';
    label = '';
    url = '';
    stack = [];
    constructor(name) {
        this.name = name;
    }
    init(params = {}) {
        let stack = [];
        let item = getItem(this.name, params);
        if (item.parent)
            stack = item.parent.stack;
        this.label = item.label;
        this.url = item.url;
        stack.push(this.current());
        this.stack = stack;
    }
    current() {
        return { name: this.name, label: this.label, url: this.url };
    }
    static for(name, params = {}) {
        let item = new this(name);
        item.init(params);
        return item;
    }
}




function getItem(__name, { id, name }) {
    let __parent = null;
    let __label = '';
    let __url = '';
    switch (__name) {
        //АДМИНКА____________________________________________________________________________________
        case 'index':
            __label = 'Главная';
            __url = '/';
            break;
        /* Заказы - сертификата */
        case 'authorized.admin.order.certificates.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Сертификаты';
            __url = route('authorized.admin.order.certificates.index');
            break;
        case 'authorized.admin.order.certificates.show':
            __parent = Breadcrumbs.for('authorized.admin.order.certificates.index');
            __label = name;
            __url = route('authorized.admin.order.certificates.show', { id });
            break;
        case 'authorized.admin.order.certificates.create':
            __parent = Breadcrumbs.for('authorized.admin.order.certificates.index');
            __label = "Создать";
            __url = route('authorized.admin.order.certificates.create');
            break;
        /* Заказы - бронирования */
        case 'authorized.admin.order.bookings.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Полетное время';
            __url = route('authorized.admin.order.bookings.index');
            break;
        case 'authorized.admin.order.bookings.show':
            __parent = Breadcrumbs.for('authorized.admin.order.bookings.index');
            __label = name;
            __url = route('authorized.admin.order.bookings.show', { id });
            break;
        case 'authorized.admin.order.bookings.create':
            __parent = Breadcrumbs.for('authorized.admin.order.bookings.index');
            __label = "Создать сертификат";
            __url = route('authorized.admin.order.bookings.create');
            break;
        /* Заказы - депозиты */
        case 'authorized.admin.order.balances.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Покупки депозитов';
            __url = route('authorized.admin.order.balances.index');
            break;
        case 'authorized.admin.order.balances.show':
            __parent = Breadcrumbs.for('authorized.admin.order.balances.index');
            __label = name;
            __url = route('authorized.admin.order.balances.show', { id });
            break;
        case 'authorized.admin.order.balances.create':
            __parent = Breadcrumbs.for('authorized.admin.order.balances.index');
            __label = "Создать";
            __url = route('authorized.admin.order.balances.create');
            break;
        /* Заказы - соревнования */
        case 'authorized.admin.order.competitions.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Соревнования';
            __url = route('authorized.admin.order.competitions.index');
            break;
        case 'authorized.admin.order.competitions.show':
            __parent = Breadcrumbs.for('authorized.admin.order.competitions.index');
            __label = name;
            __url = route('authorized.admin.order.competitions.show', { id });
            break;
        case 'authorized.admin.order.competitions.create':
            __parent = Breadcrumbs.for('authorized.admin.order.competitions.index');
            __label = "Создать";
            __url = route('authorized.admin.order.competitions.create');
            break;
        case 'authorized.admin.order.competitions.teamShow':
            __parent = Breadcrumbs.for('authorized.admin.order.competitions.index');
            __label = name;
            __url = route('authorized.admin.order.competitions.teamShow', { id });
            break;
        /* Заказы - AmoCrm */
        case 'authorized.admin.order.amo.index':
            __parent = Breadcrumbs.for('index');
            __label = 'AmoCrm (Заказы)';
            __url = route('authorized.admin.order.amo.index');
            break;
        /* Продукты */
        case 'authorized.admin.content.products.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Продукты';
            __url = route('authorized.admin.content.products.index');
            break;
        case 'authorized.admin.content.products.show':
            __parent = Breadcrumbs.for('authorized.admin.content.products.index');
            __label = name;
            __url = route('authorized.admin.content.products.show', { id });
            break;
        case 'authorized.admin.content.products.create':
            __parent = Breadcrumbs.for('authorized.admin.content.products.index');
            __label = "Создать";
            __url = route('authorized.admin.content.products.create');
            break;
        /* Категории */
        case 'authorized.admin.content.categories.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Категории';
            __url = route('authorized.admin.content.categories.index');
            break;
        case 'authorized.admin.content.categories.show':
            __parent = Breadcrumbs.for('authorized.admin.content.categories.index');
            __label = name;
            __url = route('authorized.admin.content.categories.show', { id });
            break;
        case 'authorized.admin.content.categories.create':
            __parent = Breadcrumbs.for('authorized.admin.content.categories.index');
            __label = "Создать";
            __url = route('authorized.admin.content.categories.create');
            break;
         /* Акции */
        case 'authorized.admin.content.promotions.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Акции';
            __url = route('authorized.admin.content.promotions.index');
            break;
        case 'authorized.admin.content.promotions.show':
            __parent = Breadcrumbs.for('authorized.admin.content.promotions.index');
            __label = name;
            __url = route('authorized.admin.content.promotions.show', { id });
            break;
        case 'authorized.admin.content.promotions.create':
            __parent = Breadcrumbs.for('authorized.admin.content.promotions.index');
            __label = "Создать";
            __url = route('authorized.admin.content.promotions.create');
            break;
        /* Промокоды */
        case 'authorized.admin.content.promocodes.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Промокоды';
            __url = route('authorized.admin.content.promocodes.index');
            break;
        case 'authorized.admin.content.promocodes.show':
            __parent = Breadcrumbs.for('authorized.admin.content.promocodes.index');
            __label = name;
            __url = route('authorized.admin.content.promocodes.show', { id });
            break;
        case 'authorized.admin.content.promocodes.create':
            __parent = Breadcrumbs.for('authorized.admin.content.promocodes.index');
            __label = "Создать";
            __url = route('authorized.admin.content.promocodes.create');
            break;
        /* Бронирования - депозиты ( разница ) */
        case 'authorized.admin.booking.balances.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Депозиты';
            __url = route('authorized.admin.booking.balances.index');
            break;
        /* Бронирования - депозиты ( разница ) */
        case 'authorized.admin.booking.balance-diff.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Разница по депозитам';
            __url = route('authorized.admin.booking.balance-diff.index');
            break;
        /* Бронирования - операции по депозитам */
        case 'authorized.admin.booking.times.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Операции по депозитам';
            __url = route('authorized.admin.booking.times.index');
            break;
        /* Бронирования - бронирования */
        case 'authorized.admin.booking.bookings.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Бронирования';
            __url = route('authorized.admin.booking.bookings.index');
            break;
        /* Площадки */
        case 'authorized.admin.event.platforms.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Площадки';
            __url = route('authorized.admin.event.platforms.index');
            break;
        case 'authorized.admin.event.platforms.show':
            __parent = Breadcrumbs.for('authorized.admin.event.platforms.index');
            __label = name;
            __url = route('authorized.admin.event.platforms.show', { id });
            break;
        case 'authorized.admin.event.platforms.create':
            __parent = Breadcrumbs.for('authorized.admin.event.platforms.index');
            __label = "Создать";
            __url = route('authorized.admin.event.platforms.create');
            break;
        /* Банеры */
        case 'authorized.admin.event.banners.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Баннеры';
            __url = route('authorized.admin.event.banners.index');
            break;
        case 'authorized.admin.event.banners.show':
            __parent = Breadcrumbs.for('authorized.admin.event.banners.index');
            __label = name;
            __url = route('authorized.admin.event.banners.show', { id });
            break;
        case 'authorized.admin.event.banners.create':
            __parent = Breadcrumbs.for('admin.event.banners.index');
            __label = "Создать";
            __url = route('authorized.admin.event.banners.create');
            break;
        /* Календари - бронировний */
        case 'authorized.admin.event.calendar.calendars.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Календари бронирований';
            __url = route('authorized.admin.event.calendar.calendars.index');
            break;
        case 'authorized.admin.event.calendar.calendars.show':
            __parent = Breadcrumbs.for('authorized.admin.event.calendar.calendars.index');
            __label = name;
            __url = route('authorized.admin.event.calendar.calendars.show', { id });
            break;
        case 'authorized.admin.event.calendar.calendars.create':
            __parent = Breadcrumbs.for('authorized.admin.event.calendar.calendars.index');
            __label = "Создать";
            __url = route('authorized.admin.event.calendar.calendars.create');
            break;
        /* Календари - категорий */
        case 'authorized.admin.event.calendar.categories.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Календари категорий';
            __url = route('authorized.admin.event.calendar.categories.index');
            break;
        case 'authorized.admin.event.calendar.categories.show':
            __parent = Breadcrumbs.for('authorized.admin.event.calendar.categories.index');
            __label = name;
            __url = route('authorized.admin.event.calendar.categories.show', { id });
            break;
        case 'authorized.admin.event.calendar.categories.create':
            __parent = Breadcrumbs.for('authorized.admin.event.calendar.categories.index');
            __label = "Создать";
            __url = route('authorized.admin.event.calendar.categories.create');
            break;
        /* Статистика - Аналитика */
        case 'authorized.admin.statistic.analytics.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Аналитика';
            __url = route('authorized.admin.statistic.analytics.index');
            break;
        /* Статистика - Параметры */
        case 'authorized.admin.statistic.parameters.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Аналитика';
            __url = route('authorized.admin.statistic.parameters.index');
            break;
        /* Пользователи */
        case 'authorized.admin.user.users.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Пользователи';
            __url = route('authorized.admin.user.users.index');
            break;
        case 'authorized.user.users.show':
            __parent = Breadcrumbs.for('authorized.admin.user.users.index');
            __label = name;
            __url = route('authorized.admin.user.users.show', { id });
            break;
        case 'authorized.admin.user.users.create':
            __parent = Breadcrumbs.for('authorized.admin.user.users.index');
            __label = "Создать";
            __url = route('authorized.admin.user.users.create');
            break;
        /* Роли */
        case 'authorized.admin.user.roles.index':
            __parent = Breadcrumbs.for('index');
            __label = 'Роли';
            __url = route('authorized.admin.user.roles.index');
            break;
        case 'authorized.admin.user.roles.show':
            __parent = Breadcrumbs.for('authorized.admin.user.roles.index');
            __label = name;
            __url = route('authorized.admin.user.roles.show', { id });
            break;
        case 'authorized.admin.user.roles.create':
            __parent = Breadcrumbs.for('authorized.admin.user.roles.index');
            __label = "Создать";
            __url = route('authorized.admin.user.roles.create');
            break;
    //ЛИЧНЫЙ_КАБИНЕТ_______________________________________________________________________________________
        /* Главная */
        case 'authorized.account.home.index':
            __parent = Breadcrumbs.for('/');
            __label = 'Главная';
            __url = route('authorized.account.home.index');
            break;
        /* Сертификаты */
        case 'authorized.account.order.certificates.index':
            __parent = Breadcrumbs.for('authorized.account.home.index');
            __label = 'Мои сертификаты';
            __url = route('authorized.account.order.certificates.index');
            break;
        /* Сертификаты */
        case 'authorized.account.booking.bookings.index':
            __parent = Breadcrumbs.for('authorized.account.home.index');
            __label = 'Мои бронирования';
            __url = route('authorized.account.booking.bookings.index');
            break;
        /* Депозиты */
        case 'authorized.account.booking.balances.index':
            __parent = Breadcrumbs.for('authorized.account.home.index');
            __label = 'Мои депозиты';
            __url = route('authorized.account.booking.balances.index');
            break;
        case 'authorized.account.booking.times.index':
            __parent = Breadcrumbs.for('authorized.account.booking.balances.index');
            __label = name ? 'Операции по депозиту "' + name + '"' : 'Операции по депозиту' ;
            __url = route('authorized.account.booking.times.index', { id });
            break;
        //Общие
        case 'authorized.common.profile.show':
            __parent = Breadcrumbs.for('authorized.account.home.index');
            __label = 'Профиль';
            __url = route('authorized.common.profile.show');
            break;
        default:
            break;
        
    }
    return { parent: __parent, label: __label, url: __url };
}