import React, { useEffect, useState } from "react";
// Компоненты
import Label from "../label";
import MessageError from "../../message/__errors";
// Material UI
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// Библиотеки
import FileLib from "../../../../inc/FileLib";
// Стили
import '../styles.css';

export default function InputFile({ file = null, value = '', url = '', timestamp, onChange, children, placeholder, errors, className = '', readOnly= true, ...other }) {
    if (placeholder === undefined) placeholder = children;
    const extension = url ? FileLib.getExtension(url) : null;
    let imageUrl = url;
    
    if(imageUrl){// Создание URL с параметром временной метки для обхода кэша, если это не base64
        imageUrl = url && url.startsWith('data:image/') ? url : `${url}?t=${timestamp}`;
    }
    const [_showImg, set_showImg] = useState(true);

    useEffect(() => {
        set_showImg(true);
    }, [timestamp])

    return (
        <>
            <Label>{children}</Label>
            <div>
                <ShowFile file={file} url={url} showImg={_showImg}>
                    {['png', 'jpg', 'jpeg'].includes(extension) ?
                        <img src={imageUrl} width="100%" alt={value} />
                        : ['mp4'].includes(extension) ?
                            <video src={imageUrl} width="100%" alt={value} />
                            : value
                    }
                </ShowFile>
                <Label className={`input input__file ${readOnly ? 'input__file_read-only' : ''} ${className}`}>
                    {file ?
                        <div>{file.name}</div>
                        : value ?
                            <div>Заменить</div>
                            :
                            <div>Загрузить</div>
                    }
                    <div className="input__file-icons">
                        <AttachFileIcon />
                    </div>
                    {!readOnly ?
                        <input
                            {...other}
                            type="file"
                            className="input"
                            placeholder={placeholder}
                            onChange={(e) => {onChange(e)}}
                        />
                        : null}
                </Label>
            </div>
            {errors ? <MessageError errors={errors} sx={{ width: "100%" }} /> : null}
        </>
    );
}

function ShowFile({ children, file, url, showImg }) {
    if (!url) return null;
    return (
        <div>
            {showImg ? <a href={url} target="_blank" rel="noreferrer">{children}</a> : null}
        </div>
    );
}
