//Реакт
import React from "react";
//Компоненты
import Button from "../../button/button";
import ToolTip from "../../tool-tip/toolTip";
//Mui Icons
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
//Стили
import './styles.css';

export default function CertificateTile({ id, title, body, price, oldPrice, img = null, minutes, min, max, gift=false, onClick, className = '', ...other }) {
    const handleByCertificate = () => {
        onClick(id)
    }
    return (
        <>
            <div className={`certificate-tile ${className}`}>
              
                <div className="certificate-tile_header">
                    <div className="certificate-tile_header-left">
                        <div className="certificate-tile_header-left__minutes">{minutes}</div>
                        <div className="certificate-tile_header-left__title">
                            {title}
                        </div>
                    </div>
                    <div className="certificate-tile_header-right">
                        <div className="certificate-tile_header-right__price">
                            {price} ₽
                        </div>
                    </div>
                </div>
                <img className="certificate-tile_img" src={img ?? '/no-photo.svg'}/>
                {body ? <ToolTip text={body}/> : null}
                {gift ? 
                    <div className="certificate-tile_check-row">
                        <div className="certificate-tile_check-row-label">Подарочный сертификат</div>
                        <div className="certificate-tile_check-row-icon">
                            <CheckOutlinedIcon/>
                        </div>
                    </div>
                : null}
                <div className="certificate-tile_check-row">
                    <div className="certificate-tile_check-row-label">
                        <PermIdentityOutlinedIcon/> {min + ' - ' + max}</div>
                    <div className="certificate-tile_check-row-icon">
                        <CheckOutlinedIcon/>
                    </div>
                </div>
                <Button onClick={handleByCertificate} className="certificate-title_btn" variant="primary">
                    Купить
                </Button>
            </div>
        </>
    );
}
