import ApiModel from "../../../../models/ApiModel";

export default class ApiPromocodes extends ApiModel{
    //formatterItem = formatterOrder;
    __showErrors = false;
    
    paths = {
        store: "public/promocode/check",
    }

    getParamsForStore({promocode, productId}){
        let data = {};
        if(promocode) data['promocode'] = promocode;
        if(productId) data['product_id'] = productId;
        return data;
    }
}