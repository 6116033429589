import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";
import Formatter from "../../../../models/Formatter";

export class BalanceFormatter extends Formatter { }
export default class ApiBalances extends ApiModelWithAuth {
    pathDef = 'authorized/account/booking/balances';
    paths = {
        update: "authorized/account/booking/balances/purchase",
    }

    formatterItem = BalanceFormatter.Item;
}