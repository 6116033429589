//Реакт
import React, { useEffect, useState, useContext } from 'react';
import { useBookingContext } from '../../bookingContext';
//Api и библиотеки
//Модули и контент
import { ContextApp } from "../../../../modules/app/appContext";
//Компоненты
import Counter from '../../components/counter';
import Products from '../../components/products';
import Balances from '../../components/balances';
import Calendar from '../../components/calendar';
import Input from '../../components/input';
import Button from '../../../../components/button/button';
import DropdownWrapper from '../../../../components/dropdown-wrapper';
import SlotTimes from '../../components/slot-times';
import Certificate from '../../components/certificate/certificate';
//Mui icons & components
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
//Стили
import './styles.css';

export default function StepBuy({ nextStep, handleBookByBalance, handleBookByCard, handleBookByCertificate }) {
  const { checkPermission } = useContext(ContextApp);
  const [_tab, set_tab] = useState(0);
  const [_isContactsOpen, set_isContactsOpen] = useState(false);
  const [initialData, setInitialData] = useState([null, null]);
  const [showParams, setShowParams] = useState('calendar');
  const [productType, setProductType] = useState('main');
  const {
    bookingData,
    fetchSlotTime,
    fetchPromocodeCheck,
    fetchCertificateCheck,
    setTimeId,
    setBalanceId,
    setUseCertificate,
    setCountPeople,
    setPromocodeString,
    setCertificateString,
    setProductId,
    setSelectedDate,
    resetPromocodeFields,
    setProductTime,
    setName,
    setEmail,
    setPhone,
  } = useBookingContext();

  const {
    products,
    productTime,
    balanceId,
    balanceProducts,
    balances,
    certificate,
    promocode,
    slotTime,
    isLoadingSlots,
    timeId,
    useCertificate,
    countPeople,
    productId,
    selectedDate,
    user
  } = bookingData;

  const { promocodeString, promocodeLabel, promocodeErrors, promocodeDiscount } = promocode;
  const { certificateString, certificateErrors, certificateMinutes } = certificate;
  const { name, email, phone } = user;

  const mainProducts = products.filter((product) => product.code === 'main');
  const additionalProducts = products.filter((product) => product.code !== 'main');
  const minutesByMainProducts = mainProducts.map((product) => product.minutes);
  const minutesByAdditionalProducts = additionalProducts.map((product) => product.minutes);

  useEffect(() => {
    if (selectedDate) {
      fetchSlotTime(selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (promocodeString) {
      setProductType('main');
      if (productId) fetchPromocodeCheck(promocodeString, productId);
    } else {
      const chosenTimeSlot = Object.values(slotTime).find((slot) => slot.timeId === timeId);
      if (chosenTimeSlot) {
        setProductType(chosenTimeSlot.timeId >= 22 && chosenTimeSlot.timeId <= 25 ? 'additional' : 'main');
      }
      resetPromocodeFields();
    }
  }, [promocodeString, productId]);

  useEffect(() => {
    if (productType === 'additional' && promocodeDiscount == null) {
      setProductType('additional');
    } else {
      setProductType('main');
    }
  }, [promocodeDiscount]);

  useEffect(() => {
    if (productTime) {
      const selectedProduct = productType === 'main' ? mainProducts : additionalProducts;
      const foundProduct = selectedProduct.find((product) => product.minutes === productTime);
      setProductId(foundProduct ? foundProduct.id : null);
    }
  }, [productType]);

  const handleDataChange = (index, newData) => {
    const updatedData = [...initialData];
    updatedData[index] = newData;
    setInitialData(updatedData);
  };

  const handleCalendarChange = (newData) => {
    setSelectedDate(newData);
    handleDeleteItem(1, [newData, null]);
  };

  const handleDeleteItem = (index, newData) => {
    if (index) setTimeId(null);
    else setSelectedDate(null);
    handleChoice(index);
    setInitialData(newData);
  };

  const handleChoice = (index) => {
    if (index === 0) setShowParams('calendar');
    else if (index === 1) setShowParams('slots');
  };

  const handleSlotChange = (newData) => {
    const chosenTimeSlot = slotTime.find((slot) => slot.dateStart === newData);
    if (chosenTimeSlot) {
      setTimeId(chosenTimeSlot.timeId);
      setProductType(chosenTimeSlot.timeId >= 22 && chosenTimeSlot.timeId <= 25 ? 'additional' : 'main');
    }
    handleDataChange(1, newData);
    setShowParams('calendar');
  };

  const handleCountChange = (value) => {
    setCountPeople(value);
  };

  const handleCheckCertificate = () => {
    fetchCertificateCheck(certificateString);
  };

  const handleProductChange = (value) => {
    if (value && !useCertificate) {
      const selectedProduct = productType === 'main' ? mainProducts : additionalProducts;
      const foundProduct = selectedProduct.find((product) => product.minutes === value);
      setProductId(foundProduct ? foundProduct.id : null);
    }
    setProductTime(value);
  };

  const handleBalanceChange = (value) => {
    setBalanceId(value);
  };

  const handleChangeIndex = (index) => {
    set_tab(index);
  };

  const handleChangeContactsState = () => {
    set_isContactsOpen(!_isContactsOpen)
  }

  const handlePhoneChange = (event) => {
    let str = event.target.value.replace(/[^0-9]/g, '');
    if(str.length && str[0] !== '7') str = `7${str}`;
    if(str.length >= 11) str = str.substr(0, 11);
    setPhone(str);
  }

  return (
    <div className="step-buy__content">
      <div className='step-buy__content-header'>
        <label className="step-buy__content-row_label">Способ оплаты</label>
        <div className='step-buy__content-header_tabs'>
          <button onClick={() => handleChangeIndex(0)} className={`${_tab === 0 ? 'step-buy__content-header_tabs-button--selected' : 'step-buy__content-header_tabs-button'}`}>Картой</button>
          <button onClick={() => handleChangeIndex(1)} className={`${_tab === 1 ? 'step-buy__content-header_tabs-button--selected' : 'step-buy__content-header_tabs-button'}`}>Сертификатом</button>
          {checkPermission('account.booking.booking-by-balance.create') ? <button onClick={() => handleChangeIndex(2)} className={`${_tab === 2 ? 'step-buy__content-header_tabs-button--selected' : 'step-buy__content-header_tabs-button'}`}>Депозитом</button> : null}
        </div>
      </div>
      <div className="step-buy__content-row">
        <DropdownWrapper
          className="step-buy__content-row_item"
          placeholder="Выберите дату"
          IconComponent={CalendarTodayIcon}
          initialData={initialData}
          onDelete={handleDeleteItem}
          onChoice={handleChoice}
          isLoading={isLoadingSlots}
        >
          {showParams === 'calendar' ? (
            <Calendar onSelect={(value) => handleCalendarChange(value)} />
          ) : (
            <SlotTimes
              type="certificate"
              promocodeDiscount={promocodeDiscount}
              productTime={productTime}
              products={products}
              isLoading={isLoadingSlots}
              slotTime={slotTime}
              onSelect={(value) => handleSlotChange(value)}
            />
          )}
        </DropdownWrapper>
      </div>
        <div className='step-buy__content-row-contacts'>
          <div onClick={handleChangeContactsState} className='step-buy__content-row-contacts-list'>
            <label className="step-buy__content-row-contacts-list_label">Контакты</label>
            <div className={`${_isContactsOpen ? 'step-buy__content-row-contacts-list-indicator--rotated' : 'step-buy__content-row-contacts-list-indicator'}`}>
              <KeyboardArrowUpOutlinedIcon/>
            </div>
          </div>
          <div className={`${_isContactsOpen ? 'step-buy__content-row-contacts-content--open' : 'step-buy__content-row-contacts-content--close'}`}>
            <Input onChange={(e) => setName(e.target.value)} value={name} className='step-buy__content-row-contacts-content_item'>Имя</Input>
            <Input onChange={(e) => setEmail(e.target.value)} value={email} className='step-buy__content-row-contacts-content_item'>E-mail</Input>
            <Input onChange={handlePhoneChange} value={(typeof phone === 'string' && phone.length > 0) ? `+${phone}` : ''}  placeholder="+7 (___) ___-__-__" className='step-buy__content-row-contacts-content_item'>Телефон</Input>
          </div>
        </div>
      {_tab === 0 &&
        <div className="step-buy__content-body">

          <div className="step-buy__content-row">
            <label className="step-buy__content-row_label">Человек</label>
            <Counter className="step-buy__content-row_item" countPeople={countPeople} onChange={handleCountChange} />
          </div>
          <div className="step-buy__content-row">
            <label className="step-buy__content-row_label">Время (мин)</label>
            <Products
              className="step-buy__content-row_item"
              selectedProduct={productTime}
              products={productType === 'main' ? minutesByMainProducts : minutesByAdditionalProducts}
              countPeople={countPeople}
              onChange={handleProductChange}
            />
          </div>
          <div className="step-buy__content-row">
            <Input
              errors={promocodeErrors}
              value={promocodeString}
              onChange={(e) => setPromocodeString(e.target.value)}
              className={`step-buy__content-row_item--last`}
            >
              Промокод
            </Input>
            {promocodeLabel && promocodeString && <div className="step-buy__content-row_success-label">{promocodeLabel}</div>}
          </div>
          <Button className="step-buy__content-button" onClick={handleBookByCard}>
            Оформить
          </Button>
        </div>
      }
      {_tab === 1 &&
        <div className="step-buy__content-body">
          <div className="step-buy__content-row">
            <label className="step-buy__content-row_label">Время (мин)</label>
            <Products
              className="step-buy__content-row_item"
              selectedProduct={certificateMinutes}
              products={certificateMinutes ? [certificateMinutes] : []}
              countPeople={countPeople}
              onChange={handleProductChange}
            />
          </div>
          <div className="step-buy__content-row">
            <Certificate
              errors={certificateErrors}
              value={certificateString}
              onChange={(e) => setCertificateString(e.target.value)}
              onCheck={handleCheckCertificate}
              className={`step-buy__content-row_item`}
            >
              Номер сертификата
            </Certificate>
            {certificateString && certificateMinutes && <div className="step-buy__content-row_success-label">Сертификат {certificateString} действителен!</div>}
          </div>
          <Button className="step-buy__content-button" onClick={handleBookByCertificate}>
            Оформить
          </Button>
        </div>
      }
      {_tab === 2 &&
        <div className="step-buy__content-body">
          <div className="step-buy__content-row">
            <label className="step-buy__content-row_label">Время (мин)</label>
            <Products
              className="step-buy__content-row_item"
              selectedProduct={productTime}
              products={balanceProducts}
              countPeople={countPeople}
              onChange={handleProductChange}
              checkMinutes={false}
            />
          </div>
          <div className="step-buy__content-row">
            <label className="step-buy__content-row_label">Депозит</label>
            <Balances
              className="step-buy__content-row_item--last"
              selectedBalanceId={balanceId}
              balances={balances}
              onChange={handleBalanceChange}
            />
          </div>
          <Button className="step-buy__content-button" onClick={handleBookByBalance}>
            Оформить
          </Button>
        </div>
      }
    </div>
  );
}
