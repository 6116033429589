import ApiInit from "./ApiInit";

export default class ApiModel extends ApiInit{
    __data = null;
    code = null;
    errors = null;
    __type = "GET";
    __subtype = null;
    __responseIsArray = false;
    isSendFile = false;
    isCheckPagination = false;
    paths = {};
    pathDef = null;
    formatterResponseData = (rdata) => rdata;
    formatterItem = (item) => item;
    formatterError = (errors) => errors;
    __path = null;
    __params = null;
    __showErrors = true;

    paginationCurrent = null;
    paginationNext = null;
    paginationLast = null;

    async getResponse(customParams = {}){
        let rdata = null;
        this.errors = null;
        this.code = null;
        if(!this.__path) return null;
        if(this.__type === "GET" || this.__type === "DELETE"){
            let params = this.pasreParamsForGet({...this.__params, ...customParams});
            rdata = await this._query(`${this.__path}?${params}`, this.__type);
        }else if(this.__type === "POST" || this.__type === "PATCH")
            rdata = await this._query(this.__path, this.__type, this.__params, this.__subtype);
        if(!rdata) return null;
        this.code = rdata.code;
        if(this.code > 200 && this.code <=204){
            this.addMessages('Операция выполнена успешно!', 'success')
        }else if(this.code !== 200) return this.handleError(rdata);
        if(this.isCheckPagination)
            this.checkPagination(rdata);
        let data = this.formatterResponseData(rdata);
        if(this.__responseIsArray && data){
            let _data = [];
            Object.keys(data).forEach((key)=>{
                let item = data[key];
                _data[key] = this.formatterItem(item);
            });
            return _data;
        }
 
        return this.formatterItem(data);
    }
    handleError(rdata) {
            if(!rdata.result){
                if('error' in rdata
                    && 'messages' in rdata.error) this.setErrors(rdata.error.messages);
                else if('response' in rdata
                    && 'error' in rdata.response){
                        this.setErrors([rdata.response.error]);
                        if('code' in rdata.response){
                            this.pushErrorToStorage(rdata.response)
                        }
                    } 
                else if('response' in rdata
                    && 'errors' in rdata.response){
                        let errors = [];
                        for (const field in rdata.response.errors) {
                            if (rdata.response.errors.hasOwnProperty(field)) {
                                const errorMessages = rdata.response.errors[field];
                                if(Array.isArray(errorMessages)) errorMessages.forEach(item =>{
                                    errors.push(item);
                                })
                                else if(typeof errorMessages === 'string') errors.push(errorMessages);
                            }
                        }
                        if(errors.length) this.setErrors(errors);
                    }
            }
            if(this.__showErrors) {
                if(this.errors) this.addMessages(this.errors, 'error');
                else this.addMessages(`Возникла ошибка: ${this.code}`, 'error');
            }   

        return null;
    }
    async getResponseNext(){
        let customParams = {};
        if(this.paginationNext) customParams.page = this.paginationNext;
        return await this.getResponse(customParams);
    }

    setErrors(errors){
        this.errors = this.formatterError(errors);
    }
    static Index(params){
        const api = new this({});
        api.index(params);
        return api;
    }
    static Store(params){
        const api = new this({});
        api.store(params);
        return api;
    }
    static Show(id){
        const api = new this({});
        api.show(id);
        return api;
    }
    static Update(id, params){
        const api = new this({});
        api.update(id, params);
        return api;
    }
    static Destroy(id){
        const api = new this({});
        api.destroy(id);
        return api;
    }

    static Group(key){
        const api = new this({});
        api.group(key);
        return api;
    }

    validation(){
        let errors = this._validation(this.__params);
        if(Object.keys(errors).length) return errors;
        return null;
    }
    _validation(data){ return [];}

    index(params){
        this.__responseIsArray = true;
        this.isCheckPagination = true;
        this.__params = params;
        //this.__params = this.pasreParamsForGet(params);
        this.__path = ('index' in this.paths) ? this.paths.index : this.pathDef;
        this.formatterResponseData = (rdata) =>{
            return rdata.response.data;
        }
    }
    store(params){
        this.__type = "POST";
        this.__path = ('store' in this.paths) ? this.paths.store : this.pathDef;
        this.__params = this.getParamsForStore(params);
        this.formatterResponseData = (rdata) =>{
            return rdata.response.data;
        }
    }
    show(id){
        if(id) this.__path = ('show' in this.paths) ? this.paths.show+`/${id}` : (this.pathDef) ? this.pathDef+`/${id}` : null;
        else this.__path = ('show' in this.paths) ? this.paths.show : (this.pathDef) ? this.pathDef : null;
        this.formatterResponseData = (rdata) =>{
            return rdata.response.data;
        }
    }
    update(id, params){
        this.__type = "PATCH";
        if(id) this.__path = ('update' in this.paths) ? this.paths.update+`/${id}` : (this.pathDef) ? this.pathDef+`/${id}` : null;
        else this.__path = ('show' in this.paths) ? this.paths.show : (this.pathDef) ? this.pathDef : null;
        this.__params = this.getParamsForUpdate(params);
        this.formatterResponseData = (rdata) =>{
            return rdata.response.data;
        }
    }
    destroy(id){
        this.__type = "DELETE";
        this.__path = ('destroy' in this.paths) ? this.paths.destroy+`/${id}` : (this.pathDef) ? this.pathDef+`/${id}` : null;
        this.formatterResponseData = (rdata) =>{
            return rdata.result;
        }
    }
      
    group(key){
        this.__responseIsArray = false;
        this.isCheckPagination = false;
        this.__path =  this.pathDef+`/group/${key}`;
        this.formatterResponseData = (rdata) =>{
            let result = {};
            if(!('data' in rdata.response)) return null;
            if(!('items' in rdata.response)) return null;
            result.data = rdata.response.data;
            let items = [];
            Object.keys(rdata.response.items).forEach((key)=>{
                let item = rdata.response.items[key];
                items[key] = this.formatterItem(item);
            });
            result.items = items;
            return result;

        }
    }

    getParamsForStore(params){ return params}
    getParamsForUpdate(params){ return params}


    
    checkPagination(rdata) {
        let response = rdata.response ? rdata.response : null;
        if (response && response.meta && response.meta.current_page && response.meta.last_page) {
            this.paginationCurrent = response.meta.current_page ? parseInt(response.meta.current_page) : null;
            this.paginationLast = parseInt(response.meta.last_page);
            if (this.paginationCurrent && this.paginationCurrent < this.paginationLast) {
                this.paginationNext = this.paginationCurrent + 1;
            } else {
                this.paginationNext = null;
            }
        } else {
            this.paginationNext = null;
        }
    }
}
