//Реакт
import React from "react";
//Компоненты
import Label from "../label";
import MessageError from "../../message/__errors";
//Стили
import '../styles.css';

export default function InputPassword({children, value='', placeholder, errors, className = '', ...other}){
    if(placeholder === undefined) placeholder = children;
    return <>
        <Label>{children}</Label>
        <input
            {...other}
            type="password"
            className={`input ${className}`}
            placeholder={placeholder}
            value={value}
        />
        {errors ? <MessageError errors={errors} sx={{width: "100%"}}/> : null}
    </>
}