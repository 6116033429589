//Реакт
import React from "react";
//Компоненты
import ButtonLink from "../../button-link";
//Стили
import "./styles.css";

export default function BreadcrumbsItem({ url, children, ...other }) {

  return <ButtonLink
      {...other}
      href={url}
      className={`breadcrumbs__item`}
  >
    {children}
  </ButtonLink>
}