import { combineReducers } from "redux";

import App from "./app/reducers";
import AdminOption from "./admin/option/reducers";
import AccountOption from "./account/option/reducers"
import CommonOption from "./common/option/reducers"

const reducers = combineReducers({
    app: App,
    adminOption: AdminOption,
    accountOption: AccountOption,
    commonOption: CommonOption,
});
export default reducers;
