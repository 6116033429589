//Реакт
import React from "react";
//Стили
import "./styles.css";

export default function LayoutAuth({children, className, header, ...other}){
    return <div 
        {...other}
        className={`layout-auth ${className}`}
    >
            {children}
    </div>
}