//Реакт
import React, { useState, useLayoutEffect} from "react";
//Компоненты
import Label from "../label";
import MessageError from "../../message/__errors";
//Matrial UI
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
//Стили
import './styles.css'

//при нахождении и выборе значения из списка возвращаться будет строка из БД 
export default function SelectApi({
    apiModel, //API в котором будем искать совпадения
    searchParam = "name", //поле(столбец) по которому идет поиск
    countSearch = 5, //с какого n-ного символа начнется запрос к БД
    handleRowSelect, //для получения строки из БД выбранного option
    labelNull = "id", //ключ для option
    textParams = null, //массив доп. полей для вывода в option
    value = null,
    children,
    onChange,
    placeholder,
    errors,
    className = '',
    readOnly,
    ...other
}) {
    const [searchText, setSearchText] = useState("");
    const [isFocused, setIsFocused] = useState(false);
    const [isListOpen, setIsListOpen] = useState(false);
    const [options, setOptions] = useState({});
    const [rows, setRows] = useState({});
    const handleFocus = () => {
        setIsFocused(true);
    };
    const handleToggle = () => {
        setIsListOpen(!isListOpen);
    };
  
    const handleSelectOption = (key) => {
        setSearchText(options[key]);
        setIsFocused(false);
        setIsListOpen(false);
        handleRowSelect(getRow(key));
    };

    const handleInputChange = (value) => {
        setSearchText(value);
        onChange({ target: { value: value } });
    };

    const handleReset = () => {
        onChange({ target: { value: '' } });
        handleInputChange('');
        setIsFocused(false);
        setIsListOpen(false);
        setRows({});
    }
    const getRow = (key) => {
        let row = rows.find(row => row[labelNull] == key);
        return row;
    }

    useLayoutEffect(() => {
        if (searchText.length >= countSearch) {
            const fetchData = async () => {
                let getData = apiModel.Index({[searchParam]: searchText});
                let data = await getData.getResponse();
                setRows(data);
                let options = {};
                data.map((result) => {
                    if(textParams){
                        let additionalText = textParams.map(textParam => result[textParam]).join(' ');
                        options[result[labelNull]] = result[searchParam] + ' ' + additionalText
                    }
                    else
                        options[result[labelNull]] = result[searchParam]
                });
                setOptions(options);
            };
            fetchData();
            if (searchText in options) {
                setSearchText(options[searchText]);
            }
        }
        else
            setOptions({});
    }, [searchText, apiModel]);
    if (placeholder === undefined) placeholder = children;

    return (
        <>
            <Label>{children}</Label>
            <div className={`select-wrapper ${className}`}>
                <input
                    type="text"
                    options={options}
                    value={value}
                    onChange={(e) => handleInputChange(e.target.value)}
                    onFocus={handleFocus}
                    onClick={handleToggle}
                    placeholder={placeholder}
                    className='input select-input'
                    readOnly={readOnly}
                    {...other}
                />  
                <button className={`select__reset-button ${readOnly ? 'select__reset-button--disabled' : ''}`} onClick={() => handleReset()}>
                    <DeleteOutlineOutlinedIcon />
                </button>
                {isFocused && (
                    <div className="options-dropdown">
                         {Object.keys(options).map((key) => (
                            <div className='option-item' key={key} onClick={() => handleSelectOption(key)}>
                                {options[key]}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {errors ? <MessageError errors={errors} sx={{ width: "100%" }} /> : null}
        </>
    );
}