//Реакт
import React from "react";
//Компоненты
import Label from "../label";
//Библиотеки
import DateLib from "../../../../inc/DateLib";
//Стили
import '../styles.css';
import './styles.css';

export default function InputDate({ onChange, value = '', children, placeholder, errors, className = '', ...other }) {
  if (placeholder === undefined) placeholder = children;
  const _onChange = (e) => {
    e.target.value = DateLib.formatterDateTime(e.target.value, 'en')
    onChange(e);
  }
  return (
    <>
      <Label>{children}</Label>
      <input
        id="date-input"
        {...other}
        onChange={onChange ? _onChange : null}
        value={value ? DateLib.formatterDateTime(value, 'en') : ''}
        type="date"
        className={`booking-content__input input date__input  ${className}`}
        placeholder={placeholder}
      />
    </>
  );
}