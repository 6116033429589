//Реакт
import React from "react";
//Компоненты
import Button from "../../button/button";
import Label from "../../inputs/label/label";
//Material UI
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ClearIcon from '@mui/icons-material/Clear';
//Стили
import style from "./style";
import "./styles.css"

export default function MyModal({isOpenModal, closeModal, className = '', children, title}){
    return (
        <Modal
            open={isOpenModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style.modal} className={`card ${className}`}>
                {title ? 
                <div className="card-header modal__header">
                     <Label><h3>{title}</h3></Label>
                     <Button className={'modal__header-btn universal-button'} onClick={closeModal}><ClearIcon /></Button> 
                </div>
                : ''}
                <div className="card-body modal__child-param">
                    {children}
                </div>
            </Box>
        </Modal>
    );
}