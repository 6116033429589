//Реакт
import React from "react";
//Компоненты
import Modal from "../modal";
import DownloadFileError from "../../download-file/__error";
//Стили
import "./styles.css";

export default function ModalError({
   error,
   title = "Возникла ошибка",
   children,
   ...other
}){
   let _title = <>
      {title}
      <DownloadFileError error={error}/>
   </>
   return (
      <Modal
         title={_title}
         position="high"
         {...other}
      >
         <div className="modal-error">
            <div className="modal-error__message">{error.info}</div>
         </div>
      </Modal>
   );
}