//Реакт
import React, {useContext} from "react";
//Компоненты
import ContentHeader from "./__header";
import ContentFooter from "./__footer";
import ContentLoader from "./__loader";
import { ProviderContent } from "./contentProvider";
import { ContextContent } from "./contentContext";
import { SystemAbort404 } from "../../pages/public/system/Abort404";
import { SystemAbort403 } from "../../pages/public/system/Abort403";
//Стили
import "./styles.css";

export default function ContentWithProvider({children, ...other}){
  return <ProviderContent
    {...other}
  >
    <Content>
      {children}
    </Content>
  </ProviderContent>
}

function Content({children}){
  const {id, type, isLoad, codeApi} = useContext(ContextContent);
  if(codeApi === 404) return <SystemAbort404/>
  else if(codeApi === 403) return <SystemAbort403/>
  return <div className="content"  data-id={(id) ? id : ''} data-type={type}>
    {isLoad ? <ContentLoader/> : null}
    <ContentHeader/>
    <div className="content__body">
      {children}
    </div>
    <ContentFooter/>
  </div>
}