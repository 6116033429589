//Реакт
import React from "react";
//Стили
import './styles.css';

export default function Tile({ tile, className = '', ...other }) {
  return (
    <>
        <div className="tile">
          {tile}
        </div>
    </>
  );
}
