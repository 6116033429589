import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";
import Formatter from "../../../../models/Formatter";

export class BookingFormatter extends Formatter{
    static CustomItem(data) {
        return {
          date_start: this.Date(data.date_start),
          date_end: this.Date(data.date_end),
        };
      }
}
export default class ApiBooking extends ApiModelWithAuth{
    pathDef = 'authorized/admin/booking/bookings';

    formatterItem = BookingFormatter.Item;
}

