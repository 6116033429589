//Реакт
import React, { useState, useContext, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiRole from "../../../../../app/api/authorized/admin/user/ApiRole";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputWrapper from "../../../../components/input/__wrapper";
import InputDatetime from "../../../../components/inputs/datetime/datetime";
import CheckboxBlock from "../../../../components/checkbox-block";
//Стили
import './styles.css';
import '../../../styles.css';

export default function RoleShow({ ...other}){
  return <Page { ...other} ApiModel={ApiRole}>
    <RoleShowContent { ...other}/>
  </Page>
}

function RoleShowContent({ ...other}){
  const contextPage = useContext(ContextPage);
  const contextContent = useContext(ContextContent);
  const [_id, set_id] = useState(null);
  const [_name, set_name] = useState('');
  const [_slug, set_slug] = useState('');
  const [_created, set_created] = useState(null);
  const [_updated, set_updated] = useState(null);

  const [_selectedPermissions, set_selectedPermissions] = useState([]);

  const handleSetSelectedItems = (updatedItems) => {
    set_selectedPermissions(updatedItems);
  };

  const permissions = useSelector(state => state.adminOption.permissions);

  contextContent._dataToServer.current = {
    name: _name,
    slug: _slug,
    permissions: _selectedPermissions,
  };

  useLayoutEffect(()=>{
    contextContent._handleSetData.current = (data) =>{
      set_id(data.id);
      set_name(data.name);
      set_slug(data.slug);
      set_selectedPermissions(data.permissions ?? [])
      set_created(data.created_at);
      set_updated(data.updated_at);
      contextPage.setTitle(`Роль: ${data.name}`);
      contextContent.setTitle(`Роль ${data.name}`);
      contextPage.setName(data.name);
    };
    contextContent.doLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  return <>
    {!contextContent.isCreate ?
    <InputWrapper>
      <InputText className="pages__show-input" value={_id} readOnly={true}>id</InputText>
    </InputWrapper> : null }
    <InputWrapper>
      <InputText className="pages__show-input" value={_name} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_name(e.target.value)}>Имя</InputText>
    </InputWrapper>
    <InputWrapper>
      <InputText className="pages__show-input" value={_slug} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_slug(e.target.value)}>Слаг</InputText>
    </InputWrapper>
    <InputWrapper>
      <CheckboxBlock className="pages__show-input" readOnly={!contextContent.isCreateOrEdit} data={permissions} selectedItems={_selectedPermissions} setSelectedItems={handleSetSelectedItems}>Доступы для роли:</CheckboxBlock>
    </InputWrapper>
    {!contextContent.isCreate ? 
    <InputWrapper>
      <InputDatetime className="pages__show-input" value={_created} readOnly={true}>Создан</InputDatetime>
    </InputWrapper> 
    : null }
    {!contextContent.isCreate ? 
    <InputWrapper>
      <InputDatetime className="pages__show-input" value={_updated} readOnly={true}>Изменен</InputDatetime>
    </InputWrapper> 
    : null }
  </>
}
