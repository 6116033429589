//Реакт
import React, {useContext, useState, useEffect } from "react";
//MUI
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
//Компоненты
import { ProviderMessages } from "./messagesProvider";
import { ContextMessages } from "./messagesContext";
import ModalMessage from "../../../components/modals/modal-message";
import ModalError from "../../../components/modals/modal-error";
//Библиотеки
import DateLib from "../../../../inc/DateLib";
//Стили
import "./styles.css";

export default function MessagesWithProvider({children, ...other}){
  return <ProviderMessages
    {...other}
  >
    {children}
    <div className="mdl-app-messages">
      <Messages/>
    </div>
  </ProviderMessages>
}

function Messages({children, ...other}){
   const { messages, deleteItem } = useContext(ContextMessages);
   const [isPauseHover, setIsPauseHover] = useState(false);
   const [isPauseModal, setIsPauseModal] = useState(false);
   let messageIds = Object.keys(messages);
   let now = DateLib.now('unix');
   let arr = [];
   let position = 0;
   for(let i = 0; i < messageIds.length ; i++){
      if(position > 4) continue;
      let id = messageIds[i];
      let message = messages[id];
      const _deleteItem = () => {
         return deleteItem(message.id);
      }
      arr.push(<MessagesItem 
         {...message} 
         now={now} 
         position={position}
         key={message.id} 
         deleteItem={_deleteItem}
         isPause={isPauseHover || isPauseModal}
         setIsPauseHover={setIsPauseHover}
         setIsPauseModal={setIsPauseModal}
      />);
      position++;
   }
   return arr;
}
function MessagesItem({id, message, type, createdAt, ttl, now, position, error,
      deleteItem, isPause, setIsPauseHover, setIsPauseModal}) {
   const [isVisible, setIsVisible] = useState(true);
   const [isOpenModal, setIsOpenModal] = useState(false);
   const handlePauseHoverOn = () =>{ setIsPauseHover(true);}
   const handlePauseHoverOff = () => {setIsPauseHover(false);}
   const ttlShow = ttl-300;
   const messageString = (typeof message === 'object') ? JSON.stringify(message) : message;
   const handleOpenModal = () =>{
      setIsPauseModal(true);
      setIsOpenModal(true);
   }
   const handleCloseModal = () =>{
      setIsPauseModal(false);
      setIsOpenModal(false);
   }
   useEffect(() => {//Для удаления
      if(!isPause && position === 0){
         const deleteTimeout = setTimeout(() => { 
            deleteItem(); 
         }, ttl);
         return () => {
            if(deleteTimeout) clearTimeout(deleteTimeout);
         };
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [ttl, isPause, position]); 
   useEffect(() => {//Для скрытия
      if(!isPause && position === 0){
         const hideTimeout = setTimeout(() => {
            setIsVisible(false); // Скрываем сообщение через определенное время
         }, ttlShow);
         return () => {
            clearTimeout(hideTimeout);
         };
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [ttlShow, isPause, position]);
   return <>
      <div 
         className={`mdl-app-messages__item ${type} ${isVisible ? 'show' : 'hide'}`} 
         data-id={id}
         onMouseEnter={handlePauseHoverOn}
         onMouseLeave={handlePauseHoverOff}
         >
            <IconButton 
               sx={{ color: '#fff', fontSize: 30, padding: "0 10px 0 0" }}
               onClick={handleOpenModal}
            >
                  <VisibilityIcon fontSize="inherit"/>
            </IconButton>
            {messageString}
      </div>
      {error ? 
         <ModalError
            isOpenModal={isOpenModal}
            closeModal={handleCloseModal}
            error={error}
            title={error.message}
         />
      :
         <ModalMessage
            isOpenModal={isOpenModal}
            closeModal={handleCloseModal}
            position="high"
         >{messageString}</ModalMessage>
      }
   </>
}
