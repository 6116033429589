//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
//Библиотеки и Api
import ApiBookingTime from "../../../../../app/api/authorized/admin/booking/ApiBookingTime";
import { route } from "../../../../../inc/Routes";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
//Стили
import './styles.css';

export default function BookingTimeIndex({ ...other }) {
    return <Page {...other}>
        <BookingTimeIndexContent {...other} />
    </Page>
}

function BookingTimeIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_type, set_type] = useState('');
    const [_fio, set_fio] = useState('');

    useLayoutEffect(() => {
        contextPage.setTitle(`Операции по депозитам`);
        contextContent.setTitle(`Операции по депозитам`);
    }, [contextContent, contextPage]);
    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="user"
            name="user"
        >
            Пользователь
        </ListHeaderItem>,
        <ListHeaderItem
            key="balanceType"
            name="balanceType"
        >
            Тип баланса
        </ListHeaderItem>,
        <ListHeaderItem
            key="minutes"
            name="minutes"
        >
            Минуты
        </ListHeaderItem>,
        <ListHeaderItem
            key="userBalance"
            name="userBalance"
        >
            Баланс пользователя
        </ListHeaderItem>,
        <ListHeaderItem
            key="type"
            name="type"
        >
            Тип операции
        </ListHeaderItem>,
        <ListHeaderItem
            key="status"
            name="status"
        >
            Статус операции
        </ListHeaderItem>,
        <ListHeaderItem
            key="paymentType"
            name="paymentType"
        >
            Способ оплаты
        </ListHeaderItem>,
    ];

    const filters = [
        <InputText className="input_wf" value={_fio} onChange={(e) => { set_fio(e.target.value) }}>Пользователь</InputText>,
        <InputText className="input_wf" value={_type} onChange={(e) => { set_type(e.target.value) }}>Тип</InputText>,
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-times_column-width" key="id" data-label={header[0].props.children}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="user" data-label={header[1].props.children}><a href={route(`authorized.admin.user.users.show`,{id: row.user_id})}>{row.user_fio}</a></ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="balanceType" data-label={header[2].props.children}>{row.user_balance_type_name}</ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="minutes" data-label={header[3].props.children}>{row.minutes}</ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="user_balance" data-label={header[4].props.children}>{row.user_balance_balance}</ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="type" data-label={header[5].props.children}>{row.type_name}</ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="status" data-label={header[6].props.children}>{row.status_name}</ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="paymentType" data-label={header[7].props.children}>{row.payment_type_name}</ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiBookingTime}
            renderRow={renderRow}
            searchParams={{ userFio: _fio, type: _type }}
        />
    </>
}
