//Реакт
import React from "react";
//Компоненты
import Label from "../label";
//Стили
import '../styles.css';

export default function InputPhone({onChange, value='', id = "input-phone", children, className = '', placeholder, errors, ...other}){
    if(placeholder === undefined) placeholder="+7 (___) ___-__-__";
    const _onChange = (e) => {
        let str = e.target.value.replace(/[^0-9]/g, '');
        if(str.length && str[0] !== '7') str = `7${str}`;
        if(str.length >= 11) str = str.substr(0, 11);
        e.target.value = str;
        onChange(e);
    }
    if(value === null){
        value = '';
    } else if(typeof value === 'string' && value.length > 0) value = `+${value}`;
    return <>
        <Label>{children}</Label>
        <input
            {...other}
            onChange={_onChange}
            id={id}
            type="text"
            className={`booking-content__input input ${className}`}
            value={value}
            placeholder={placeholder}
        />
    </>
}