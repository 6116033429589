const style = {
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        height: '70%',
        boxSizing: 'border-box',
        display: 'flex',
    },
    input: {
        width: '30%',
        margin: '10px'
    },
    param: {
        height: '100%',
        width: '100%',
    }
};
export default style;