import ApiModel from "../../../../models/ApiModel";
import Formatter from "../../../../models/Formatter";

export class BookingByBalanceFormatter extends Formatter{}
export default class ApiBookingByBalance extends ApiModel{
    pathDef = 'public/booking/book-by-balance';
    __subtype = "searchParams";
    
    getParamsForStore({name, email, phone, date, minutes, timeId, balanceId}){
        let data = {};
        if(name) data['bookingData[name]'] = name;
        if(email) data['bookingData[email]'] = email;
        if(phone) data['bookingData[phone]'] = phone;
        if(minutes) data['bookingData[minutes]'] = minutes;
        if(timeId) data['slotTime[timeId]'] = timeId;
        if(date) data['slotTime[date]'] = date;
        if(balanceId) data['time[balance_user_id]'] = balanceId;
        return data;
    }
    formatterItem = BookingByBalanceFormatter.Item;
}

