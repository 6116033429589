//Реакт
import React from "react";
//Компоненты
import MessageErrors from "../../../../components/message/__errors/message__errors";
//Mui Icons
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
//Стили
import './styles.css';

export default function Certificate({value = '', children, placeholder, className = '', errors, onCheck, onChange, ...other}){
    if(placeholder === undefined) placeholder = children;
    return <>
        <div className={`certificate ${className}`}>
            <input
                {...other}
                type="text"
                value={value ? value : ''}
                className={`certificate-input`}
                placeholder={placeholder}
                onChange={onChange}
            />
            <button onClick={onCheck} className="certificate-button__search"><SearchOutlinedIcon></SearchOutlinedIcon></button>
        </div>

        {errors ? <MessageErrors errors={errors} sx={{width: "100%"}}/> : null}
    </>
}