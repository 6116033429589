//Реакт
import React, { useLayoutEffect, useContext } from "react";
//Библиотеки и Api
import DateLib from "../../../../../inc/DateLib";
import { route } from "../../../../../inc/Routes";
import ApiOrderAmo from "../../../../../app/api/authorized/admin/order/ApiOrderAmo";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import ButtonLinkShow from "../../../../components/button-link/__show";
import Button from "../../../../components/button/button";
//Стили
import './styles.css';

export default function OrderAmoIndex({ ...other }) {
    return <Page {...other}>
        <OrderAmoContent {...other} />
    </Page>
}

function OrderAmoContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    useLayoutEffect(() => {
        contextPage.setTitle(`AmoCrm (Заказы)`);
        contextContent.setTitle(`AmoCrm (Заказы)`);
    }, [contextContent, contextPage]);

    const synchronizeData = async () => {
        try {
            const apiStore = ApiOrderAmo.Store({});
            const responseData = await apiStore.getResponse();
        } catch (error) {}
    };

    const header = [
        <ListHeaderItem
            key="id"
            name="id"
            isSort={false}
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="name"
            name="name"
            isSort={false}
        >
            Пользователь
        </ListHeaderItem>,
        <ListHeaderItem
            key="phone"
            name="phone"
            isSort={false}
        >
            Телефон
        </ListHeaderItem>,
        <ListHeaderItem
            key="email"
            name="email"
            isSort={false}
        >
            Email
        </ListHeaderItem>,
        <ListHeaderItem
            key="type_name"
            name="type_name"
            isSort={false}
        >
            Тип
        </ListHeaderItem>,
        <ListHeaderItem
            key="code"
            name="code"
            isSort={false}
        >
            Код сертификата
        </ListHeaderItem>,
        <ListHeaderItem
           key="price"
           name="price"
           isSort={false}
        >
           Итого, руб
        </ListHeaderItem>,
        <ListHeaderItem
            key="product"
            name="product"
            isSort={false}
        >
            Продукт
        </ListHeaderItem>,
        <ListHeaderItem
            key="promocode_name"
            name="promocode_name"
            isSort={false}
        >
            Промокод
        </ListHeaderItem>,
        <ListHeaderItem
            key="status"
            name="status"
            isSort={false}
        >
            Статус
        </ListHeaderItem>,
        <ListHeaderItem
            key="created_at"
            name="created_at"
            isSort={false}
        >
            Создан
        </ListHeaderItem>,
        <ListHeaderItem
            key="updated_at"
            name="updated_at"
            isSort={false}
        >
            Изменен
        </ListHeaderItem>,
        <ListHeaderItem
            key="show"
            name="show"
            isSort={false}
        >
        </ListHeaderItem>,
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-amo_column-width" key="id" data-label={header[0].props.children}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="name" data-label={header[1].props.children}>{row.name}</ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="phone" data-label={header[2].props.children}>{row.phone}</ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="email" data-label={header[3].props.children}>{row.email}</ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="type_name" data-label={header[4].props.children}>{row.type_name}</ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="code" data-label={header[5].props.children}><a href={row.certificate_file}>{row.code}</a></ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="price" data-label={header[6].props.children}>{row.total} ₽</ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="product" data-label={header[7].props.children}>
                {row.products.map((product) => (
                <div key={product.id}>
                    <div>Время: {product.minutes}</div>
                    <div>Цена: {product.price} ₽ ({product.total} ₽)</div>
                </div>
                ))}
            </ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="promocode_name" data-label={header[8].props.children}>{row.promocode_name}</ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="status" data-label={header[9].props.children}>{row.status}</ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="created_at" data-label={header[10].props.children}>{DateLib.formatterDateTime(row.created_at)}</ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="updated_at" data-label={header[11].props.children}>{DateLib.formatterDateTime(row.updated_at)}</ListBodyItem>,
            <ListBodyItem className="admin-amo_column-width" key="show">
                {<ButtonLinkShow href={route(`authorized.admin.order.certificates.show`,{id: row.certificate_id})} />}
            </ListBodyItem>,
        ];
    }
    return <>
        <Button onClick={synchronizeData}>Синхронизация</Button>
        <List
            header={header}
            ApiModel={ApiOrderAmo}
            renderRow={renderRow}
        />
    </>
}
