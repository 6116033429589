//Реакт
import React from 'react';
//Компоненты
import Button from '../../../../components/button/button';
//Стили
import './styles.css'

export default function StepAfterpay({ formData, goToFirst }) {
    return (
        <>
            <div className='step-afterpay__content'> 
                <div className='step-afterpay__content_header'>Мы оформили заказ, удачного полета!</div>
                <div className='step-afterpay__content-card'>
                    <div className='step-afterpay__content-card-row_info-block'>
                        <div className='step-afterpay__content-card-row_info-data'>Если вы не нашли удобной для вас даты</div>
                        <div className='step-afterpay__content-card-row_info-data'>посещения или у вас возникли сложности,</div>
                        <div className='step-afterpay__content-card-row_info-data'>свяжитесь с нами</div>
                    </div>
                    <div className='step-afterpay__content-card-row_contacts-block'>
                        <div className='step-afterpay__content-card-row_contacts-data'>8 (800) 555-59-66</div>
                        <div className='step-afterpay__content-card-row_contacts-data'>8 (812) 633-07-07</div>
                    </div>
                </div>
                <Button onClick={goToFirst} className='step-afterpay__content-button'>Закрыть</Button>
            </div>
        </>
    );
}