import React, { useState, useRef, useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import SyncIcon from '@mui/icons-material/Sync';
import './styles.css';

export default function DropdownWrapper({ children, className, placeholder, initialData, IconComponent, onDelete, onChoice, isLoading }) {
    //Состояние отображения выпадающего списка
    const [showDropdown, setShowDropdown] = useState(false);
    //Массив выыводимых значений (должны быть строки)
    const [selectedValues, setSelectedValues] = useState(initialData || []);
    const dropdownRef = useRef(null);

    //Отслеживание клиска на дочерний компонент
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };
 
    //Выбор активного компонента
    const handleChoiceClick = (index) => {
        onChoice(index);
    }

    //jтслеживание клика вне компонента
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    //отслеживание изменений отображаемых значений
    useEffect(() => {
        setSelectedValues(initialData ?? []);
        // Проверяем, что все элементы массива не являются null
        if (initialData.every(value => value !== null)) {
            setShowDropdown(false);
        }
    }, [initialData]);

    //отслеживание и передача сигнала по удалению значения из списка выбраных
    const handleClear = (index) => {
        const newValues = [...selectedValues];
        newValues[index] = null;
        setSelectedValues(newValues);
        onDelete(index, newValues);
    }

    return (
        <div className={`dropdown-wrapper ${className}`} ref={dropdownRef}>
            <div onClick={() => setShowDropdown(!showDropdown)} className={`${!selectedValues.every(value => value === null) ? 'dropdown-wrapper__values-wrapper--field' : 'dropdown-wrapper__values-wrapper--empty'}`}>
                {selectedValues.map((value, index) => (
                    value !== null ? (
                        <div onClick={() => handleChoiceClick(index)} key={index} className="dropdown-wrapper__value">
                            {value}
                            <ClearIcon onClick={() => handleClear(index)} className='dropdown-wrapper__value_delete-icon' />
                        </div>
                    ) : null
                ))}
                {placeholder && selectedValues.every(value => value === null)  ?
                    <div className='dropdown-wrapper__placeholder'>{placeholder}</div>
                    : ''
                }
                {IconComponent && 
                    <div className="dropdown-wrapper__icon">
                        {!isLoading ? <IconComponent/> : <SyncIcon className="spin"/> }
                    </div>  
                }
            </div>
            {showDropdown && !isLoading  && (
                <div className="dropdown-wrapper__content">
                    {React.Children.map(children, (child, index) =>
                        React.cloneElement(child, { initialData: selectedValues[index] })
                    )}
                </div>
            )}
        </div>
    );
}