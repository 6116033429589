import StorageLocal from "./StorageLocal";
import {setAccessToken, setRefreshToken, setExpAccessToken, setExpRefreshToken, setLang, setUserPermissions} from "../app/reducers/app/actions";
export default class InitApp{
    static async authDataFromStorageLocal(){
        let accessToken = await StorageLocal.get('ACCESS_TOKEN');
        let refreshToken = await StorageLocal.get('REFRESH_TOKEN');
        let permissions = await StorageLocal.get('PERMISSIONS');
        if(accessToken && refreshToken && permissions){
            permissions = permissions.split(',')
            let authData = this.parseTokens({accessToken, refreshToken});
            if(authData) return { refreshToken, accessToken, permissions, ...authData};
        }
        return null;
    }
    static parseTokens({accessToken, refreshToken}){
        try {
            let arrAT = accessToken.split('.');
            let dataAT = JSON.parse(atob(arrAT[1]));
            let arrRT = refreshToken.split('.');
            let dataRT = JSON.parse(atob(arrRT[1]));
            return {
                id: dataAT.user_id,
                user: dataAT.user,
                lang: dataAT.lang,
                expAccessToken: dataAT.exp *1000,
                expRefreshToken: dataRT.exp *1000,
            };
        } catch (error) {}
        return null;
    }
    static setData({dispatch, accessToken, refreshToken, expAccessToken = null, expRefreshToken = null, lang = null, permissions = []}){
        dispatch(setAccessToken(accessToken));
        dispatch(setRefreshToken(refreshToken));
        dispatch(setUserPermissions(permissions));
        dispatch(setExpAccessToken(expAccessToken));
        dispatch(setExpRefreshToken(expRefreshToken));
        dispatch(setLang(lang));
    }
}