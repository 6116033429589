import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";
import Formatter from "../../../../models/Formatter";

export class BookingByCardFormatter extends Formatter{}
export default class ApiBookingByCard extends ApiModelWithAuth{
    pathDef = 'authorized/account/booking/book-by-card';
    __subtype = "searchParams";

    getParamsForStore({name, email, phone, productId, date, timeId, countPeople, promocode}){
        let data = {};
        if(name) data['order[name]'] = name;
        data['order[email]'] = email ?? null;
        data['order[phone]'] = phone ?? null;
        if(promocode) data['promocode'] = promocode;
        if(productId) data['order[product_id]'] = productId;
        if(timeId) data['slotTime[timeId]'] = timeId;
        if(date) data['slotTime[date]'] = date;
        if(countPeople) data['bookingData[count_people]'] = countPeople;
        return data;
    }

    formatterItem = BookingByCardFormatter.Item;
}

