//Реакт
import React, { useState, useLayoutEffect } from 'react';
//Библиотеки

//Компоненты
import Button from '../../../../components/button/button';
//Стили
import './styles.css'

function PayTimer({ OrderCreatedAt }) {
    let diff = 0;
    if (OrderCreatedAt) diff = ((new Date()).getTime() - OrderCreatedAt.getTime()) / 1000;
    let time = (15 * 60) - diff;
    const [seconds, setSeconds] = useState((time < 0) ? 0 : time);
    useLayoutEffect(() => {
        const timer = setTimeout(() => {
            if (seconds > 0) setSeconds(seconds - 1);
        }, 1000);
        return () => clearInterval(timer);
    }, [seconds]);
    let m = parseInt(seconds / 60);
    let s = parseInt(seconds % 60);
    if (s < 10) s = `0${s}`;
    return <div>{m}:{s}</div>
}

export default function StepPreview({ nextStep, previewData }) {
    const actionPay = async () => {
        let payUrl = previewData.url_for_pay;
        if (!payUrl) return null;
        //window.open(payUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        window.open(payUrl, '_blank');
        nextStep()
    }
    return (
        <>
            <div className='step-preview__content'>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <label className='step-preview__content-row_label'>Дата полета</label>
                            </td>
                            <td>
                                <label className='step-preview__content-row_data'>{previewData.slot_time_date}</label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className='step-preview__content-row_label'>Время</label>
                            </td>
                            <td>
                                <label className='step-preview__content-row_data'>{previewData.slot_time_start}</label>
                            </td>
                        </tr>
                        <tr>
                            <td className='step-preview__content-row_label'></td>
                            <td className='step-preview__content-row_info'>Необъодимо прибыть за час до указанного времени</td>
                        </tr>
                        {previewData.products.map((product, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td>
                                        {index < 1 
                                            ? <label className='step-preview__content-row_label'>Тариф</label>
                                            : <label className='step-preview__content-row_label'>Доп.</label>
                                        }
                                    </td>
                                    <td>
                                        <label className='step-preview__content-row_data'>{product.name + ' ' + '(' + product.category_name + ')'}</label>
                                    </td>
                                </tr>
                                {index < 1 &&
                                    <tr>
                                        <td></td>
                                        <td>
                                            <label className='step-preview__content-row_data'>{product.tarif}</label>
                                        </td>
                                    </tr>
                                }
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
                <div className='step-preview__content-row_contacts-block'>
                    <div className='step-preview__content-row_contacts-data'>Служба поддержки</div>
                    <div className='step-preview__content-row_contacts-data'>8 800 555-59-66</div>
                    <div className='step-preview__content-row_contacts-data'>8 (812) 633-07-07</div>
                </div>
                <Button onClick={() => actionPay()} className='step-preview__content-button'>
                    <div className="step-preview__content-button-container">
                        <div className="step-preview__content-button-container_item--centered">Оплатить {previewData.total} ₽</div>
                        <div className="step-preview__content-button-container_item--end"><PayTimer OrderCreatedAt={previewData.created_at} /></div>
                    </div>
                </Button>
            </div>
        </>
    );
}