//Реакт
import React, { useState, useEffect, useRef } from "react";
//Redux
import { useSelector } from "react-redux";
import { setMenu } from "../../../app/reducers/app/actions";
import store from "../../../app/reducers/store";
//Компоненты
import Menu from "../../components/menu";
import PageContainer from "../page/__container";
//Material UI
import { IconButton } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
//Стили
import "./styles.css";

export default function LayoutDefault({ children, className = '', breadcrumb, header, ...other }) {
  const [isOpen, setIsOpen] = useState(localStorage.getItem("menu") === "true");
  const [isOverlayVisible, setIsOverlayVisible] = useState(isOpen);
  const layoutRef = useRef(null);

  useEffect(() => {
      store.dispatch(setMenu(isOpen));
      localStorage.setItem("menu", isOpen);
  }, [isOpen]);

  const openMenu = () => {
      setIsOpen(true);
      setIsOverlayVisible(true);
  };

  const closeMenu = () => {
      setIsOpen(false);
      setIsOverlayVisible(false);
  };

  const toggleOverlay = () => {
      setIsOverlayVisible(!isOverlayVisible);
      setIsOpen(!isOpen);
  };

  useEffect(() => {
      function handleClickOutside(event) {
          if (layoutRef.current && !layoutRef.current.contains(event.target)) {
              closeMenu();
          }
      }
      document.addEventListener("click", handleClickOutside);
      return () => {
          document.removeEventListener("click", handleClickOutside);
      };
  }, []);

  const scrollTo = (isUp = true) => {
      if (layoutRef && layoutRef.current) {
          layoutRef.current.scrollIntoView({ behavior: "smooth", block: (isUp) ? 'start' : 'end' });
      }
  };

  return (
      <div
          {...other}
          ref={layoutRef}
          className={`layout-default ${className}`}
      >
          <Menu isOpen={isOpen} openMenu={openMenu} closeMenu={closeMenu} />
          {isOverlayVisible && <div className={`overlay ${isOverlayVisible ? 'visible' : ''}`} onClick={toggleOverlay}></div>}
          <PageContainer breadcrumb={breadcrumb}>
              {children}
          </PageContainer>
          <div className="layout-default__btn-scroll">
              <IconButton size="large" title="Перейти вверх страницы" onClick={() => scrollTo(true)}><ArrowDropUpIcon fontSize="inherit" /></IconButton>
              <IconButton size="large" title="Перейти вниз страницы" onClick={() => scrollTo(false)}><ArrowDropDownIcon fontSize="inherit" /></IconButton>
          </div>
      </div>
  );
}