//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
//Библиотеки и Api
import DateLib from "../../../../../inc/DateLib";
import ApiPromotion from "../../../../../app/api/authorized/admin/content/ApiPromotion";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import InputDate from "../../../../components/inputs/date"
import ButtonLinkShow from "../../../../components/button-link/__show";
import ButtonLinkAdd from "../../../../components/button-link/__add";
//Material UI
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
//Стили
import './styles.css';

export default function PromotionIndex({ ...other }) {
    return <Page {...other}>
        <PromotionIndexContent {...other} />
    </Page>
}

function PromotionIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_id, set_id] = useState('');
    const [_name, set_name] = useState('');
    const [_annotation, set_annotation] = useState('');
    const [_priority, set_priority] = useState('');
    const [_date_start, set_date_start] = useState('');
    const [_date_end, set_date_end] = useState('');
    
    useLayoutEffect(() => {
        contextPage.setTitle(`Акции`);
        contextContent.setTitle(`Акции`);
    }, [contextContent, contextPage]);
    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="name"
            name="name"
        >
            Акция
        </ListHeaderItem>,
         <ListHeaderItem
            key="file_name"
            name="file_name"
        >
            Файл
        </ListHeaderItem>,
        <ListHeaderItem
            key="file_url"
            name="file_url"
        >
            Превью
        </ListHeaderItem>,
        <ListHeaderItem
            key="priority"
            name="priority"
        >
            Приоритет
        </ListHeaderItem>,
        <ListHeaderItem
            key="date_start"
            name="date_start"
        >
            Дата активации
        </ListHeaderItem>,
        <ListHeaderItem
            key="date_end"
            name="date_end"
        >
            Дата окончания
        </ListHeaderItem>,
        <ListHeaderItem
            key="created_at"
            name="created_at"
        >
            Создан
        </ListHeaderItem>,
        <ListHeaderItem
            key="updated_at"
            name="updated_at"
        >
            Изменен
        </ListHeaderItem>,
        <ListHeaderItem
            key="is_active"
            name="is_active"
        >
            Актив.
        </ListHeaderItem>,
        <ListHeaderItem
            key="create"
            name="create"
            isSort={false}
        >
            {contextContent.checkPermission('create') ? <ButtonLinkAdd href={contextPage.routes.create.url()} /> : null}
        </ListHeaderItem>,
    ];

    const filters = [
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>id</InputNumber>,
        <InputText className="input_wf" value={_name} onChange={(e) => { set_name(e.target.value) }}>Акция</InputText>,
        <InputText className="input_wf" value={_priority} onChange={(e) => { set_priority(e.target.value) }}>Приоритет</InputText>,
        <InputDate className="input_wf" value={_date_start} onChange={(e) => { set_date_start(e.target.value) }}>Дата активации</InputDate>,
        <InputDate className="input_wf" value={_date_end} onChange={(e) => { set_date_end(e.target.value) }}>Дата окончания</InputDate>
    ];
    
    const renderRow = (row, i) => {
        return [
            <ListBodyItem key="id" data-label={header[0].props.children}>{row.id}</ListBodyItem>,
            <ListBodyItem key="name" data-label={header[1].props.children}>{row.name}</ListBodyItem>,
            <ListBodyItem key="file_name" data-label={header[2].props.children}>{ row.file_name ? row.file_name.substr(row.file_name.length - 10, 10) : null}</ListBodyItem>,
            <ListBodyItem key="file_url" data-label={header[3].props.children}>
                {
                    row.file_url ? 
                        <a target="_blank" href={row.file_url}>
                            <img src={`${row.file_url}?t=${new Date().getTime()}`} width="100%" alt={row.file_url}/>
                        </a>
                    : null
                }
            </ListBodyItem>,
            <ListBodyItem key="priority" data-label={header[4].props.children}>{row.priority}</ListBodyItem>,
            <ListBodyItem key="date_start" data-label={header[5].props.children}>{DateLib.formatterDateTime(row.date_start)}</ListBodyItem>,
            <ListBodyItem key="date_end" data-label={header[6].props.children}>{DateLib.formatterDateTime(row.date_end)}</ListBodyItem>,
            <ListBodyItem key="created_at" data-label={header[7].props.children}>{DateLib.formatterDateTime(row.created_at)}</ListBodyItem>,
            <ListBodyItem key="updated_at" data-label={header[8].props.children}>{DateLib.formatterDateTime(row.updated_at)}</ListBodyItem>,
            <ListBodyItem key="is_active" data-label={header[9].props.children}>
                {
                    row.is_active ? <div className="is-active_icon--success"><VerifiedIcon/></div>
                    : <div className="is-active_icon--danger"><NewReleasesIcon/></div> 
                }
            </ListBodyItem>,
            <ListBodyItem key="show">
                {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.show.url({ id: row.id })} /> : null}
            </ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiPromotion}
            renderRow={renderRow}
            searchParams={{ id: _id, name: _name, priority: _priority,  dateStart: _date_start,  dateEnd: _date_end}}
        />
    </>
}
