export class Permission{
    name = "";
    prefix = "";

    constructor(name, prefix = "") {
        this.name = name;
        this.prefix = prefix;
    }
    getPermission(name){
        return `${this.prefix}${name}`;
    }
}