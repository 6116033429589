export default class Formatter {
    static get Item() {
        return (data) => {
          if (!data) {
            return null;
          }
      
          return {
            ...data,
            id: this.Int(data.id),
            updated_at: this.Date(data.updated_at),
            created_at: this.Date(data.created_at),
            ...this.CustomItem(data),
          };
        };
      }
    static CustomItem(data){
        return {};
    }
    static Int(value){
        return value ? parseInt(value) : null;
    }
    static Date(value){
        return value ? new Date(value) : null;
    }
}