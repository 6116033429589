import TypeConst from "./consts";

export function setIsLoad(isLoad){
    return {
        type: TypeConst.SET_STATUS_LOAD,
        isLoad,
    }
}
export function setTitle(title){
    return {
        type: TypeConst.SET_TITLE,
        title,
    }
}
export function setError(error){
    return {
        type: TypeConst.SET_ERROR,
        error,
    }
}
export function setAccessToken(accessToken){
    return {
        type: TypeConst.SET_ACCESS_TOKEN,
        accessToken,
    }
}
export function setRefreshToken(refreshToken){
    return {
        type: TypeConst.SET_REFRESH_TOKEN,
        refreshToken,
    }
}
export function setExpAccessToken(expAccessToken){
    return {
        type: TypeConst.SET_EXP_ACCESS_TOKEN,
        expAccessToken,
    }
}
export function setExpRefreshToken(expRefreshToken){
    return {
        type: TypeConst.SET_EXP_REFRESH_TOKEN,
        expRefreshToken,
    }
}
export function setMenu(menu) {
    return {
        type: TypeConst.SET_MENU,
        menu,
    }
}
export function setFilter(filter) {
    return {
        type: TypeConst.SET_FILTER,
        filter,
    }
}
export function setLang(lang) {
    return {
        type: TypeConst.SET_LANG,
        lang,
    }
}
export function setUserPermissions(userPermissions) {
    return {
        type: TypeConst.SET_USER_PERMISSIONS,
        userPermissions,
    }
}
export const updateMenuGroupState = (slug) => ({
    type: TypeConst.UPDATE_MENU_GROUP_STATE,
    slug
  });
