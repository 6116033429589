import TypeConst from "./consts";

export function setOrderProducts(orderProducts) {
    return {
        type: TypeConst.SET_ORDER_PRODUCTS,
        orderProducts,
    }
}
export function setOrderPromocodes(orderPromocodes) {
    return {
        type: TypeConst.SET_ORDER_PROMOCODES,
        orderPromocodes,
    }
}
export function setPaymentStatuses(paymentStatuses) {
    return {
        type: TypeConst.SET_PAYMENT_STATUSES,
        paymentStatuses,
    }
}
export function setBookingTypes(bookingTypes) {
    return {
        type: TypeConst.SET_BOOKING_TYPES,
        bookingTypes,
    }
}
