//Реакт
import React from "react";
//Компоненты
import ButtonLink from "../../button-link";
//Стили
import "./styles.css";
import styles from "../style";

export default function Menu({ children, Icon, isOpen = false, path, closeMenu, active, selectedClassName='isActive', className='', ...other }) {

  let childrenContent = children;

  if (Array.isArray(children)) {
    childrenContent = children.map((child) => child.props.children);
    childrenContent = String(childrenContent);
    childrenContent = childrenContent.replace(',', ' ')
  } else if (typeof children === 'object') {
    childrenContent = children.props.children;
  }

  return (
    <div
      {...other}
      className={`${isOpen ? 'is-open' : ''}`}
      title={childrenContent}
    >
      <div className={`menu__item ${className} ${active ? selectedClassName : ''}`}>
        <ButtonLink
          className={`menu__item-link ${!isOpen ? 'center' : ''}`}
          href={path}
          onClick={closeMenu}
        >
          <div className="menu__item-icon">
            <Icon className="menu__item-icon--mui" />
          </div>
          <div className="menu__item-text">
            {isOpen ? children : null} 
          </div>
        </ButtonLink>
      </div>
    </div>
  );
}