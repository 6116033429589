import { childs, Route } from "./Routes";
import { Permission } from "./Permission";
const valids = ['index', 'test', 'create', 'show'];
export class Pages{
    all = [];
    //constructor() {}
    add(path, pages = {'index': null, 'create': null, 'show': null}, prefixPermission){
        let keys = Object.keys(pages);
        let items = childs(path);
        let routes = {};
        prefixPermission = `${prefixPermission}.`;
        for(let slug in items){
            let name = `${path}.${slug}`;
            let permission = `${prefixPermission}${slug}`;
            let mask = items[slug];
            routes[slug] = new Route(name, {mask, permission: new Permission(permission, prefixPermission)})
        }
        //Задаем очередность чтобы не было перекрытия типа url/:id потом url/create

        //дублирование valids и pages
        for(let slug of valids){
            if(keys.indexOf(slug) !== -1 && slug in routes){
                this.all.push({type: slug, Page: pages[slug], route: routes[slug], routes, path: path});
            }
        }
        //Добавляем остатки
        for(let slug in pages){
            if(valids.indexOf(slug) === -1 && slug in routes){
                this.all.push({type: slug, Page: pages[slug], route: routes[slug], routes, path:path});
            }
        }
        return true;
    }
}