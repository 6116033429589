import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";
import Formatter from "../../../../models/Formatter";

export class UserFormatter extends Formatter{}
export default class ApiUser extends ApiModelWithAuth{
    pathDef = 'authorized/admin/user/users';

    formatterItem = UserFormatter.Item;
}

