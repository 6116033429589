import ApiModel from "../../../models/ApiModel";
import Formatter from "../../../models/Formatter";

export class BookingProductFormatter extends Formatter{}
export default class ApiBookingProducts extends ApiModel{
    pathDef = 'public/products/booking-products';

    formatterItem = BookingProductFormatter.Item;
}

