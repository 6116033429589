import React, {useEffect, useState} from "react";
import "./styles.css";

export default function SelectItem({ isActive = false, busyTime = null, isDisable = false, children, ...other }) {
  const [animationTime, setAnimationTime] = useState(0);

  useEffect(() => {
    if (busyTime != null && !isDisable) {
      setAnimationTime(100 - busyTime);
    }
  }, [busyTime, isDisable]);

  let wrapperStyle = {}
  let overlayStyle = {}
  if (busyTime != null && !isDisable) {
    wrapperStyle = {
      position: 'relative',
      zIndex: 1, // Устанавливаем z-index для основного элемента
    };

    overlayStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: `${0 + animationTime}%`,
      backgroundColor: '#1669DF', // Цвет второго элемента
      transition: 'width 0.5s ease', // Анимация изменения ширины
      opacity: 0.3,
      borderRadius: '0.25rem',
      zIndex: -1, // Устанавливаем z-index для дополнительного элемента
    };
  }
  return (
    <div className="select-item__wrapper">
      <div className={`select-item ${(!isDisable && isActive) ? 'active' : ''} ${isDisable ? 'disable' : ''}`} {...other} style={wrapperStyle}>
        {children}
        {busyTime != null && !isDisable ?
          <div style={overlayStyle}></div>
          : null
        }
      </div>
    </div>
  );
}