//Реакт
import React from "react";
//Компоненты
import BreadcrumbsItem from "./__item";
//Стили
import "./styles.css";

export default function Breadcrumbs({ breadcrumb, ...other }) {
    let max = breadcrumb.stack.length-1;
    return <div
        {...other}
        className={`breadcrumbs`}
    >
        {breadcrumb.stack.map((item, i) => {
            return item.label ? <BreadcrumbsItem 
            url={i !== max ? item.url : null}
            key={i}>
                {item.label}
                {i !== max ? '/' : null}
            </BreadcrumbsItem> : null;
        })}
    </div>
}