//Реакт
import React from 'react';
//Mui Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
//Стили
import './styles.css';

export default function BookingHeader({ step, prevStep }) {
    return (
        <>
            <div className='booking-header__content'>
                <ArrowBackIcon className={`${step > 1 && step < 3 ? '' : 'booking-header__item--hide'}`} onClick={prevStep} /> 
                <h3 className="booking-header__label">ЗАПИСЬ НА ПОЛЕТ</h3>
                <CloseIcon className='booking-header__item--hide'/> 
            </div>
        </>
    );
}