import React, { useState, useEffect } from 'react';
import Label from "../inputs/label";
import Checkbox from "../inputs/checkbox";
import "./styles.css";

export default function CheckboxBlock({ children, data = {}, selectedItems = [], setSelectedItems, readOnly=false, className='', ...other }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [localSelectedItems, setLocalSelectedItems] = useState(selectedItems);

    useEffect(() => {
        setLocalSelectedItems(selectedItems);
    }, [selectedItems]);

    const handleCheckboxChange = (itemId) => {
        let updatedSelectedItems = [...localSelectedItems];
        if (localSelectedItems.includes(itemId)) {
            updatedSelectedItems = localSelectedItems.filter((selectedItemId) => selectedItemId !== itemId);
        } else {
            updatedSelectedItems = [...localSelectedItems, itemId];
        }
        setLocalSelectedItems(updatedSelectedItems);
        setSelectedItems(updatedSelectedItems);
    };

    const filteredData = Object.keys(data).filter(item => data[item].toLowerCase().includes(searchQuery.toLowerCase()));

    return (
        <>
            <div className='checkbox-block__name'>
                <Label>{children}</Label>
            </div>
            <div className={`checkbox-block ${className}`}>
                <div className='checkbox-block__header'>
                    <input
                        type="text"
                        className='checkbox-block__header-search input'
                        placeholder='Поиск ...'
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
                <div className='checkbox-block__body'>
                    {filteredData.map((itemId) => (
                        <div key={itemId}>
                            <Checkbox
                                value={localSelectedItems.includes(Number(itemId))}
                                onChange={() => handleCheckboxChange(Number(itemId))}
                                readOnly={readOnly}
                            />
                            <span>{data[itemId]}</span>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}