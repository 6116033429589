//Реакт
import React, { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
//Модули Content
import Page from "../../../modules/page";
//Системные представления
import { SystemAbort } from "./Abort";
//Хранилище
import { setTitle as setTitleRedux } from "../../../../app/reducers/app/actions";

export default function SystemAbort404WithPage() {
    const dispatch = useDispatch();
    useLayoutEffect(()=>{
        dispatch(setTitleRedux("Страница не обнаружена"));
    });
    return <Page type="error">
        <SystemAbort404 />
    </Page>
};
export function SystemAbort404() {
    return <SystemAbort code={404} text="Страница не обнаружена">
        Неправильно набран адрес или такой страницы не существует
    </SystemAbort>
};