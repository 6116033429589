import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";
import Formatter from "../../../../models/Formatter";

export class BalanceDiffFormatter extends Formatter{}
export default class ApiBalanceDiff extends ApiModelWithAuth{
    pathDef = 'authorized/admin/booking/balance-diff';

    formatterItem = BalanceDiffFormatter.Item;
}

