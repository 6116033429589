import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";
import Formatter from "../../../../models/Formatter";

export class CategoryCalendarFormatter extends Formatter{
    static CustomItem(data) {
        return {
          date_start: this.Date(data.date_start),
          date_end: this.Date(data.date_end),
        };
      }
}
export default class ApiCategoryCalendar extends ApiModelWithAuth{
    pathDef = 'authorized/admin/event/category/calendars';

    formatterItem = CategoryCalendarFormatter.Item;
}