//Реакт
import React, { useState, useContext, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiUser from "../../../../../app/api/authorized/admin/user/ApiUser";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Компоненты
import InputPassword from "../../../../components/inputs/password/password";
import InputText from "../../../../components/inputs/text";
import InputPhone from "../../../../components/inputs/phone/phone";
import InputWrapper from "../../../../components/input/__wrapper";
import InputDatetime from "../../../../components/inputs/datetime/datetime";
import CheckboxBlock from "../../../../components/checkbox-block";
//Стили
import './styles.css';
import '../../../styles.css';

export default function UserShow({ ...other}){
  return <Page { ...other} ApiModel={ApiUser}>
    <UserShowContent { ...other}/>
  </Page>
}

function UserShowContent({ ...other}){
  const contextPage = useContext(ContextPage);
  const contextContent = useContext(ContextContent);
  const [_id, set_id] = useState(null);
  const [_name, set_name] = useState('');
  const [_middlename, set_middlename] = useState('');
  const [_surname, set_surname] = useState('');
  const [_email, set_email] = useState('');
  const [_phone, set_phone] = useState('');
  const [_password, set_password] = useState('');
  const [_passwordConfirmation, set_passwordConfirmation] = useState('');
  const [_created, set_created] = useState(null);
  const [_updated, set_updated] = useState(null);

  const [_selectedRoles, set_selectedRoles] = useState([]);

  const handleSetSelectedItems = (updatedItems) => {
    set_selectedRoles(updatedItems);
  };

  const roles = useSelector(state => state.adminOption.roles);

  contextContent._dataToServer.current = {
    name: _name,
    middle_name: _middlename,
    surname: _surname,
    email: _email,
    phone: _phone,
    roles: _selectedRoles,
    password: _password,
    password_confirmation: _passwordConfirmation
  };
  useLayoutEffect(()=>{
    contextContent._handleSetData.current = (data) =>{
      set_id(data.id);
      set_name(data.name);
      set_middlename(data.middle_name);
      set_surname(data.surname);
      set_email(data.email);
      set_phone(data.phone);
      set_selectedRoles(data.roles);
      set_password(data.password);
      set_created(data.created_at);
      set_updated(data.updated_at);
      set_passwordConfirmation(data.password_confirmation);
      contextPage.setTitle(`Пользователь: ${data.full_name}`);
      contextContent.setTitle(`Пользователь ${data.full_name}`);
      contextPage.setName(data.surname);
    };
    contextContent.doLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  return <>
    {!contextContent.isCreate ?
    <InputWrapper>
      <InputText className="pages__show-input" value={_id} readOnly={true}>id</InputText>
    </InputWrapper> : null }
    <InputWrapper>
      <InputText className="pages__show-input" value={_name} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_name(e.target.value)}>Имя</InputText>
    </InputWrapper>
    <InputWrapper>
      <InputText className="pages__show-input" value={_surname} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_surname(e.target.value)}>Фамилия</InputText>
    </InputWrapper>
    <InputWrapper>
      <InputText className="pages__show-input" value={_middlename} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_middlename(e.target.value)}>Отчество</InputText>
    </InputWrapper>
    <InputWrapper>
      <InputText className="pages__show-input" value={_email} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_email(e.target.value)}>Почта</InputText>
    </InputWrapper>
    <InputWrapper>
      <InputPhone className="pages__show-input" value={_phone} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_phone(e.target.value)}>Телефон</InputPhone>
    </InputWrapper>
    <InputWrapper>
      <InputPassword className="pages__show-input" value={_password} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_password(e.target.value)}>Пароль</InputPassword>
    </InputWrapper>
    <InputWrapper>
      <InputPassword className="pages__show-input" value={_passwordConfirmation} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_passwordConfirmation(e.target.value)}>Подтверждение пароля</InputPassword>
    </InputWrapper>
    <InputWrapper>
      <CheckboxBlock className="pages__show-input" readOnly={!contextContent.isCreateOrEdit} data={roles} selectedItems={_selectedRoles} setSelectedItems={handleSetSelectedItems}>Роли</CheckboxBlock>
    </InputWrapper>
    {!contextContent.isCreate ? 
    <InputWrapper>
      <InputDatetime className="pages__show-input" value={_created} readOnly={true}>Создан</InputDatetime>
    </InputWrapper> 
    : null }
    {!contextContent.isCreate ? 
    <InputWrapper>
      <InputDatetime className="pages__show-input" value={_updated} readOnly={true}>Изменен</InputDatetime>
    </InputWrapper> 
    : null }
  </>
}
