import ApiModelWithAuth from "../../../models/ApiModelWithAuth";

export default class ApiAppInit extends ApiModelWithAuth{
    pathDef = 'authorized/common/app-init';
    index(params){
        this.__responseIsArray = false;
        this.isCheckPagination = false;
        this.__params = params;
        //this.__params = this.pasreParamsForGet(params);
        this.__path = ('index' in this.paths) ? this.paths.index : this.pathDef;
        this.formatterResponseData = (rdata) =>{
            return rdata.response;
        }
    }
}
