//Реакт
import React, { useState, useContext, useLayoutEffect } from "react";
//Библиотеки и Api
import ApiProfile from "../../../../app/api/authorized/common/ApiProfile";
//Модули Content
import Page from "../../../modules/page";
import { ContextPage } from "../../../modules/page/pageContext";
import { ContextContent } from "../../../modules/content/contentContext";
//Компоненты
import InputText from "../../../components/inputs/text";
import InputWrapper from "../../../components/input/__wrapper/input__wrapper";
import InputEmail from "../../../components/inputs/email/email";
import InputPhone from "../../../components/inputs/phone/phone";
import InputPassword from "../../../components/inputs/password/password";
//Стили
import './styles.css';

export default function ProfileShow({ ...other}){
  return <Page ApiModel={ApiProfile} showNoId={true} { ...other}>
    <ProfileShowContent { ...other}/>
  </Page>
}

function ProfileShowContent({ ...other}){
  const contextPage = useContext(ContextPage);
  const contextContent = useContext(ContextContent);
  const [_id, set_id] = useState('');
  const [_name, set_name] = useState('');
  const [_surname, set_surname] = useState('');
  const [_middleName, set_middleName] = useState('');
  const [_email, set_email] = useState('');
  const [_phone, set_phone] = useState('');
  const [_newPassword, set_newPassword] = useState('');
  const [_newPasswordConfirm, set_newPasswordConfirm] = useState('');
  const [_currPassword, set_currPassword] = useState('');

  contextContent._dataToServer.current = {
    name: _name,
    surname: _surname,
    middle_name: _middleName,
    email: _email,
    phone: _phone,
    password: _newPassword,
    password_confirmation: _newPasswordConfirm,
    current_password: _currPassword,
  };
  useLayoutEffect(()=>{
    contextContent._handleSetData.current = (data) =>{
      set_id(data.id);
      set_name(data.name);
      set_surname(data.surname);
      set_middleName(data.middle_name);
      set_email(data.email);
      set_phone(data.phone);
      set_newPassword('');
      set_newPasswordConfirm('');
      set_currPassword('');
      contextPage.setTitle(`Профиль`);
      contextContent.setTitle(`Профиль`);
      contextPage.setName(`Профиль`);
    };
    contextContent.doLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return <>
    <InputWrapper>
      <InputText className="pages__show-input" value={_name} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_name(e.target.value)}>Имя</InputText>
    </InputWrapper>
    <InputWrapper>
      <InputText className="pages__show-input" value={_surname} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_surname(e.target.value)}>Фамилия</InputText>
    </InputWrapper>
    <InputWrapper>
      <InputText className="pages__show-input" value={_middleName} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_middleName(e.target.value)}>Отчество</InputText>
    </InputWrapper>
    <InputWrapper>
      <InputEmail className="pages__show-input" value={_email} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_email(e.target.value)}>Email</InputEmail>
    </InputWrapper>
    <InputWrapper>
      <InputPhone className="pages__show-input" value={_phone} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_phone(e.target.value)}>Телефон</InputPhone>
    </InputWrapper>
    <InputWrapper>
      <InputPassword className="pages__show-input" value={_currPassword} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_currPassword(e.target.value)}>Текущий пароль</InputPassword>
    </InputWrapper>
    <InputWrapper>
      <InputPassword className="pages__show-input" value={_newPassword} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_newPassword(e.target.value)}>Новый пароль</InputPassword>
    </InputWrapper>
    <InputWrapper>
      <InputPassword className="pages__show-input" value={_newPasswordConfirm} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_newPasswordConfirm(e.target.value)}>Новый пароль*</InputPassword>
    </InputWrapper>
  </>
}
