//React
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//Redux
import { useSelector } from 'react-redux';

export const useErrorHandler = () => {
    const error = useSelector(state => state.app.error);

    const navigate = useNavigate();
    useEffect(() => {
        if (error) {
            switch (error.code) {
                case 600:
                    navigate('/authorized/common/profile');
                    break;
                // другие обработчики ошибок
                default:
                    console.error(`Unhandled error ${error.code}: ${error.message}`);
                    break;
            }
        }
    }, [error, navigate]);
};